"use client"

import { AxiosRequest, dev_console_log } from "@utils"
import { Fragment, useEffect, useState } from "react"
import { MdEdit } from "@react-icons/all-files/md/MdEdit"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { AnimatedContent, CustomModal, ProgressHeatmap, LearningTimesForm } from "@client-components"
import { Switch } from "@headlessui/react"
import { BACKEND_ROOT } from "@constants"
import { cloneDeep } from 'lodash';
import { useRouter } from "next/navigation"
import Link from "next/link"
import { useTranslate } from "@hooks"
import { useParams } from 'next/navigation';
import colors from "tailwindcss/colors"
import { withStore } from "@store-components"
import { Line } from 'rc-progress';



const LearnWithEveProgress = ({ initial, user }) => {
    const { lang } = useParams()

    const [progress, setProgress] = useState(initial)

    const [showUnmasteredLOsOnly, setShowUnmasteredLOsOnly] = useState(false)
    const [completion, setCompletion] = useState(0)
    const [totalTimeSpent, setTotalTimeSpent] = useState("00:00:00")
    const [masteredLOs, setMasteredLOs] = useState([])
    const [modal, setModal] = useState({ open: false })
    const [loading, setLoading] = useState("")
    const router = useRouter()
    const translate = useTranslate()

    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })


    const calculateTotalTimeSpent = (learningPerformance) => {
        let totalSeconds = 0;

        learningPerformance.forEach(outcome => {
            const [hours, minutes, seconds] = outcome.timeSpent.split(':').map(Number);
            totalSeconds += (hours * 3600) + (minutes * 60) + seconds;
        });

        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.ceil(totalSeconds % 60);

        const pad = num => String(num).padStart(2, '0');

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }


    const calculateMaxPerformanceLevel = allQuestions => {
        const binaryMap = [0, 0, 0]; // Initial binary mapping for difficulty levels [3, 2, 1]

        // proccess allQuestions based on max levelType to get level
        if (allQuestions.length > 0) {
            // filter range questions to get passed questions
            allQuestions.filter(q => q.passed).forEach(q => {
                binaryMap[3 - q.difficultyLevel] = 1;
            });

            // all questions is not passed 
            if (binaryMap.every(digit => digit == 0)) {
                // needs improvements
                binaryMap[3 - 1] = 1;
            }
        }

        // Convert binary mapping to decimal level
        const level = parseInt(binaryMap.join(''), 2);
        return level
    }


    const mapLevelCompletionRatio = (level) => {
        const levelsCompletionRatios = [0, 0.20, 0.50, 0.70, 0.80, 0.90, 0.95, 1]

        return levelsCompletionRatios[level]
    }


    useEffect(() => {
        // do some calculations on learningPerformance
        const learningPerformance = progress.learningPerformance

        setTotalTimeSpent(calculateTotalTimeSpent(learningPerformance))

        const performanceLevels = learningPerformance.map(lp => ({ lo: lp.learningOutcome, level: calculateMaxPerformanceLevel(lp.questions) }))
        const mastered = performanceLevels.filter(i => i.level > 4).map(i => i.lo)
        setMasteredLOs(mastered)

        const total = performanceLevels.length
        const completionRatios = performanceLevels.map(i => mapLevelCompletionRatio(i.level))
        const totalCompletionRatio = ((completionRatios.reduce((a, b) => a + b, 0) / total) * 100).toFixed(2)

        setCompletion(+totalCompletionRatio > 1 ? totalCompletionRatio : 1)
    }, [progress])


    const handleUpdateLearningTimes = async (body) => {
        await AxiosRequest.put(`${BACKEND_ROOT}/course/updateCourseProgress/${progress._id}`, body)
            .then(res => {
                setProgress(res.data)
                closeModal()
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }


    const handleStudy = async (learningOutcomeId) => {
        setLoading("Please, Wait till your lesson is generated!")
        await AxiosRequest.post(`${BACKEND_ROOT}/course/${progress._id}/getOrCreateLOStudy/${learningOutcomeId}`,)
            .then(res => {
                router.push(`/${lang}/dashboard/study/${res.data._id}`)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
        setLoading("")
    }


    const handleStartAssessment = async (learningOutcomeId, idx) => {
        setLoading("Please, Wait till your assessment is generated!")

        await AxiosRequest.post(`${BACKEND_ROOT}/course/generateAssessment`, {
            courseId: progress.course._id,
            LOsIds: [learningOutcomeId]
        }).then(res => {
            let nextLearningOutcomeId = "last";

            if (idx < progress.learningPerformance.length - 1) {
                nextLearningOutcomeId = progress.learningPerformance[idx + 1].learningOutcome;
            }

            router.push(`/${lang}/dashboard/assessment/${res.data.hash}?progress_id=${progress._id}&course_id=${progress.course._id}&current_lo_id=${learningOutcomeId}&nlo_id=${nextLearningOutcomeId}`)
        }).catch(err => {
            dev_console_log(err.message || err.code);
        })
        setLoading("")
    }

    return (
        <Fragment>
            {/* loading layer */}
            {
                loading &&
                <div className="fixed inset-0 flex flex-col justify-center items-center bg-black/50 z-50 text-gray-200 gap-y-4 max-md:gap-y-2 font-semibold text-lg">
                    <img src="/logo-1.png" className="animate-bounce rounded-full w-[15%]" alt="loading" />
                    <h1 className="text-center min-w-fit max-md:text-sm">{translate(loading)}</h1>
                    <AiOutlineLoading3Quarters className="w-[5%] h-fit animate-spin" />
                </div>
            }

            {/* title */}
            <h1 className="text-2xl font-semibold">
                {translate("Discover Your Full Potential with Learn with Eve - Tailored One-to-One Tutoring, Your Pathway to Excellence")}
            </h1>

            {/* paragraph */}
            <p>
                {translate(
                    `
                Embark on a transformative learning journey with "Learn with Eve", our advanced one-to-one tutoring platform.
                Benefit from personalized guidance as AI and expert tutors tailor their approach to your unique learning style,
                ensuring a deep understanding of subjects and the confidence to excel in your studies.
                Elevate your education with Learn with Eve and unlock your full academic potential joyfully.
                `.trim()
                )}
            </p>

            {/* progress summary */}
            <div className="flex flex-col p-4 gap-y-4 my-4 bg-gray-100 rounded-lg shadow border border-gray-300">
                {/* statistics */}
                <div className="grid grid-cols-2 gap-x-2">
                    <h1 className="font-bold text-xl text-blue-400 col-span-2">{translate(`My Statistics`)}:</h1>
                    <h2>{translate(`Number of Learning Outcomes`)}:</h2>
                    <span>{progress.course.learningOutcomes.length}</span>

                    <h2>{translate(`Total time spent`)}:</h2>
                    <span>{totalTimeSpent}</span>

                    <h2>{translate(`Number of mastered Learning Outcomes`)}:</h2>
                    <span>{masteredLOs.length}</span>
                </div>

                {/* goal */}
                <div className='flex flex-col'>
                    <div className="flex justify-between gap-x-2 items-center">
                        <h2 className="font-bold text-blue-400 text-xl">{translate(`My Learning Times`)}:</h2>
                        <button className="icon-btn-sm" onClick={() => openModal()}>
                            <MdEdit />
                        </button>
                    </div>
                    <p>{translate(`Learners who set a goal are 75% more likely to complete the course. You can always change it.`)}</p>

                    {/* learning times */}
                    <div className="flex flex-wrap gap-4">
                        {
                            progress.learningTimes.map((lt, idx) => (
                                <div key={idx} className="w-[240px] flex flex-col rounded border-2 p-4 gap-y-2 border-dashed border-gray-300 bg-gray-50">
                                    {/* time idx */}
                                    <span className="font-semibold">{translate(`Time`)} {idx + 1}</span>

                                    {/* alarm time */}
                                    <div className="flex items-center justify-between text-sm">
                                        <span className="text-start font-semibold">{translate(`Alarm Time`)}:</span>
                                        <input className="text-green-600 min-w-fit bg-transparent" type="time" value={lt.alarmTime} readOnly disabled />
                                    </div>

                                    {/* repeat days */}
                                    <div className="flex flex-col text-sm gap-y-1">
                                        <span className="text-start font-semibold">{translate(`Repeat Days`)}</span>
                                        <div className="flex flex-wrap gap-1 items-center">
                                            {lt.repeatDays.map((day, dIdx) => <span key={dIdx} className="rounded-full px-2 bg-green-600 text-gray-50">{translate(day)}</span>)}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    {/* learning times form */}
                    <CustomModal open={modal.open} onClose={closeModal} className={`max-w-[1080px] min-w-[720px]`}>
                        <LearningTimesForm initial={cloneDeep(progress.learningTimes)} handleFormAction={handleUpdateLearningTimes} />
                    </CustomModal>
                </div>

                {/* completion */}
                <div className='flex flex-col'>
                    <h1 className='text-blue-400 font-bold text-xl'>{translate(`My Completion Percentage`)}:</h1>
                    <Line
                        strokeWidth={1.5}
                        trailWidth={1.5}
                        percent={completion}
                        trailColor={colors.blue[100]}
                        strokeColor={colors.blue[400]}
                    />
                </div>

                {/* performance heatmap */}
                <div className='flex flex-col gap-y-2'>
                    <h1 className='text-blue-400 font-bold text-xl'>{translate(`My Performance Heatmap`)}:</h1>
                    <ProgressHeatmap p={progress} />
                </div>
            </div>

            {/* learning outcomes header */}
            <div className="flex justify-between gap-x-4">
                <h1 className="text-2xl font-semibold">
                    {translate("Enjoy learning with Eve by selecting a Learning Outcome")}
                </h1>
                <div className="flex justify-end gap-x-4 items-center">
                    <h2>{translate(`Unmastered Only`)}</h2>
                    <Switch
                        checked={showUnmasteredLOsOnly}
                        onChange={() => setShowUnmasteredLOsOnly(!showUnmasteredLOsOnly)}
                        className={`bg-gray-200 border-gray-300 flex h-[25px] min-w-[50px] items-center rounded-full border shadow`}
                    >
                        <span
                            className={`
                                ${showUnmasteredLOsOnly ? 'translate-x-full bg-blue-500 hover:bg-blue-400' : 'translate-x-0 bg-gray-400'} 
                                w-1/2 h-full transition-all duration-500 rounded-full
                            `}
                        />
                    </Switch>
                </div>
            </div>

            {/* learning outcomes list */}
            <div className="flex flex-col relative max-md:gap-y-4">
                <div className="bg-blue-200 w-[10px] absolute md:inset-x-0 left-[5px] mx-auto h-full rounded-full" />
                {
                    progress.learningPerformance
                        .filter(lp => !(showUnmasteredLOsOnly && masteredLOs.includes(lp.learningOutcome)))
                        .map((lp, idx) => {

                            return (
                                <div key={idx} className={`w-full min-w-fit flex max-md:flex-row-reverse ${idx % 2 == 1 && 'md:flex-row-reverse'}`}>
                                    <AnimatedContent
                                        animate={`${idx % 2 == 1 ? 'animate-fade-left' : 'md:animate-fade-right animate-fade-left'}`}
                                        className="w-[95%] md:w-[45%] p-4 rounded-lg shadow border border-gray-300 bg-gray-100 flex flex-col gap-y-4"
                                    >
                                        <h1 className="text-xl font-semibold">{translate(progress.course.learningOutcomes.find(lo => lo._id == lp.learningOutcome).statement)}</h1>
                                        <p className="flex gap-x-2">
                                            <span>{translate(`Time Spent`)}:</span>
                                            <span>{lp.timeSpent}</span>
                                        </p>
                                        <div className="flex flex-wrap gap-2 text-sm mt-4">
                                            <button className="submit-btn" onClick={() => handleStudy(lp.learningOutcome)}>{translate(`Study`)}</button>
                                            <button className="submit-btn-outline" onClick={() => handleStartAssessment(lp.learningOutcome, idx)}>{translate(`Start Personalized Assessment`)}!</button>
                                        </div>
                                    </AnimatedContent>

                                    <div className="w-[5%] md:w-[10%] flex md:justify-center items-center z-10">
                                        <div className="w-[20px] h-[20px] rounded-full bg-blue-400" />
                                    </div>
                                </div>
                            )
                        })
                }
            </div>

            {/* subscribe button */}
            {
                (progress.userCourseRole == "Student" && !user.plan) &&
                <AnimatedContent animate="animate-fade-up">
                    <Link className="submit-btn-outline capitalize mt-2 max-w-xl mx-auto w-full" href={`/${lang}/subscription-options`}>
                        {translate(`subscribe to see more`)}
                    </Link>
                </AnimatedContent>
            }
        </Fragment>
    )
}


export default withStore(LearnWithEveProgress)