"use client"

import { useState } from "react"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import RichBox from "../RichBox"
import { useTranslate } from "@hooks"


const PostForm = ({ initial, handleFormAction }) => {
    const translate = useTranslate()

    const [content, setContent] = useState(initial?.content || "")
    const [loading, setLoading] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true)
        await handleFormAction(content)
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col p-8 gap-y-4 text-start">
            {/* content */}
            <h1 className="text-3xl text-gray-500">
                {translate("What's in your mind?")}
            </h1>

            <RichBox
                defaultValue={content}
                onChange={v => setContent(v)}
            />

            <button className="submit-btn" disabled={loading || !content}>
                {
                    loading ? <AiOutlineLoading3Quarters className="animate-spin text-[20px]" />
                        : initial ? translate(`Save Edits`)
                            : translate(`Publish`)
                }
            </button>
        </form>
    )
}


export default PostForm