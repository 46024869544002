"use client"

import { withStore } from "@store-components"
import { useParams, usePathname, useRouter } from "next/navigation"
import { useDispatch, useSelector } from "react-redux";
import { clearUser, getUser, getUserStatistics, setUserStatistics, resetUserStatistics, setUser } from "@lib/reducers/userReducer";
import { useTranslate } from "@hooks";
import NotificationsCenter from "./NotificationsCenter";
import Cookies from "js-cookie";
import { useEffect, useState, Fragment } from "react";
import YearRanking from "./YearRanking";
import CoinsStore from "./CoinsStore";
import CustomModal from "./CustomModal"
import StreakProgress from "./StreakProgress"
import { IoPerson } from "@react-icons/all-files/io5/IoPerson";
import { VscCalendar } from "@react-icons/all-files/vsc/VscCalendar";
import { MdLanguage } from "@react-icons/all-files/md/MdLanguage";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { ImExit } from "@react-icons/all-files/im/ImExit";
import { RiDashboardFill } from "@react-icons/all-files/ri/RiDashboardFill";
import { FaListUl } from "@react-icons/all-files/fa/FaListUl";
import { AiFillFire } from "@react-icons/all-files/ai/AiFillFire";
import { GiTwoCoins } from "@react-icons/all-files/gi/GiTwoCoins";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { AxiosRequest, formatNumber, dev_console_log, ReactSwal } from "@utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { TiChartBar } from "@react-icons/all-files/ti/TiChartBar";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import Link from 'next/link'
import Image from "next/image";
import { BACKEND_ROOT } from "@constants";
import { setLang } from "@lib/reducers/langReducer";
import { jwtDecode } from "jwt-decode";


const ClientHeader = () => {
    const translate = useTranslate()

    const user = useSelector(getUser)
    const userStatistics = useSelector(getUserStatistics)
    const dispatch = useDispatch()

    const { lang } = useParams()
    const pathname = usePathname()
    const router = useRouter()

    const [modal, setModal] = useState({ open: false })
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    useEffect(() => {
        dispatch(setLang(lang))
    }, [lang])

    const handleLogout = () => {
        // clear cookie
        Cookies.remove('access_token')

        // clear store user
        dispatch(clearUser())

        router.push(`/${lang}`)
    }

    useEffect(() => {
        if (user) {
            AxiosRequest.get(`${BACKEND_ROOT}/auth/refreshAccessToken`).then(res => {
                const user = jwtDecode(res.data.access_token).user

                dispatch(setUser(user))

                if (user.role == 'Student') {
                    AxiosRequest.get(`${BACKEND_ROOT}/user/getStatistics`)
                        .then(res => {
                            dispatch(setUserStatistics(res.data))
                        }).catch(err => {
                            dev_console_log(`Failed to fetch user statistics: ${err.message}`)
                        })
                } else {
                    dispatch(resetUserStatistics())
                }

            }).catch(err => {
                dev_console_log(`Failed to refresh access token: ${err.message}`)
                handleLogout()
            })
        }
    }, [])


    return (
        <div className="flex items-center gap-x-2">
            {user && <ToastContainer />}

            {/* language */}
            <Menu>
                <MenuButton className="nav-icon-btn hover:underline">
                    <MdLanguage fontSize={20} />
                    <span className="max-sm:hidden capitalize">{translate('language')}</span>
                    <MdExpandMore fontSize={24} />
                </MenuButton>

                <MenuItems transition anchor="bottom end" className={`nav-menu`}>
                    <MenuItem as={Link} href={pathname.replace(lang, "en")} className="px-3 py-1 hover:bg-blue-200 capitalize">
                        {translate("English")}
                    </MenuItem>

                    <MenuItem as={Link} href={pathname.replace(lang, "ar")} className="px-3 py-1 hover:bg-blue-200 capitalize">
                        {translate("Arabic")}
                    </MenuItem>

                    <MenuItem as={Link} href={pathname.replace(lang, "fr")} className="px-3 py-1 hover:bg-blue-200 capitalize">
                        {translate("French")}
                    </MenuItem>

                    <MenuItem as={Link} href={pathname.replace(lang, "de")} className="px-3 py-1 hover:bg-blue-200 capitalize">
                        {translate("German")}
                    </MenuItem>
                </MenuItems>
            </Menu>

            {/* logged in */}
            {
                user ?
                    <Fragment>
                        <CustomModal open={modal.open} onClose={closeModal} className={`max-w-3xl`}>
                            <StreakProgress />
                        </CustomModal>

                        {/* level, streak & coins big screen */}
                        {
                            user.role == "Student" &&
                            <div className="flex items-center gap-x-2 max-md:hidden h-full">
                                {/* spacer */}
                                |

                                <button onClick={() => { dev_console_log(`level=${userStatistics.level}`) }} className="nav-icon-btn">
                                    <FaStar fontSize={20} />
                                    <span className="capitalize">{userStatistics.level}</span>
                                </button>

                                <button onClick={() => openModal()} className="nav-icon-btn">
                                    <AiFillFire fontSize={20} />
                                    <span className="capitalize">{formatNumber(userStatistics.streakDays)}</span>
                                </button>

                                <button onClick={() => { dev_console_log(`coins=${userStatistics.coins}`) }} className="nav-icon-btn">
                                    <GiTwoCoins fontSize={20} />
                                    <span className="capitalize">{formatNumber(userStatistics.coins)}</span>
                                </button>
                            </div>
                        }

                        {/* spacer */}
                        |

                        {/* coins store */}
                        {user.role == "Student" && <CoinsStore />}

                        {/* notifications center */}
                        <NotificationsCenter />

                        {/* profile */}
                        <Menu>
                            <MenuButton className="nav-icon-btn w-[30px] h-[30px] rounded-full border border-gray-50 overflow-hidden">
                                {
                                    user.image ? <Image alt="user profile" width={30} height={30} src={user.image} className="h-full w-full object-cover" />
                                        : <IoPerson className="h-full w-full p-1" />
                                }
                            </MenuButton>

                            <MenuItems transition anchor="bottom end" className={`nav-menu`}>
                                <MenuItem as={Link} href={`/${lang}/dashboard`} className="px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                    <span className="capitalize">{translate(`dashboard`)}</span>
                                    <RiDashboardFill fontSize={20} />
                                </MenuItem>

                                {
                                    user.role == "Student" &&
                                    <MenuItem as={`button`} onClick={() => {
                                        ReactSwal.fire({
                                            width: 'auto',
                                            showConfirmButton: false,
                                            html: <YearRanking />
                                        })
                                    }} className="px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                        <span className="capitalize">{translate(`ranking`)}</span>
                                        <TiChartBar fontSize={20} />
                                    </MenuItem>
                                }

                                <MenuItem as={Link} href={`/${lang}/calendar`} className="px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                    <span className="capitalize">{translate(`Calendar`)}</span>
                                    <VscCalendar fontSize={20} />
                                </MenuItem>

                                {/* level, streak & coins small screen */}
                                {
                                    user.role == "Student" &&
                                    <div className="md:hidden w-full">
                                        <MenuItem as="button" onClick={() => { dev_console_log(`level=${userStatistics.level}`) }} className="w-full px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                            <span className="capitalize">{translate(`level`)}</span>

                                            <div className="flex items-center gap-x-1">
                                                <FaStar fontSize={20} />
                                                <span className="capitalize">{userStatistics.level}</span>
                                            </div>
                                        </MenuItem>

                                        <MenuItem as="button" onClick={() => openModal()} className="w-full px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                            <span className="capitalize">{translate(`streak`)}</span>

                                            <div className="flex items-center gap-x-1">
                                                <AiFillFire fontSize={20} />
                                                <span className="capitalize">{formatNumber(userStatistics.streakDays)}</span>
                                            </div>

                                        </MenuItem>

                                        <MenuItem as="button" onClick={() => { dev_console_log(`coins=${userStatistics.coins}`) }} className="w-full px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                            <span className="capitalize">{translate(`coins`)}</span>

                                            <div className="flex items-center gap-x-1">
                                                <GiTwoCoins fontSize={20} />
                                                <span className="capitalize">{formatNumber(userStatistics.coins)}</span>
                                            </div>
                                        </MenuItem>
                                    </div>
                                }

                                <MenuItem as={Link} href={`/${lang}/profile`} className="px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2">
                                    {
                                        user.firstName && user.lastName ? `${user.firstName} ${user.lastName}`
                                            : user.firstName || user.lastName || user.email
                                    }
                                    <IoPerson />
                                </MenuItem>

                                <MenuItem
                                    as={`button`}
                                    className="capitalize px-3 py-1 hover:bg-blue-200 flex items-center justify-between gap-x-2"
                                    onClick={handleLogout}
                                >
                                    {translate('logout')}
                                    <ImExit />
                                </MenuItem>

                            </MenuItems>
                        </Menu>
                    </Fragment>
                    :
                    <Fragment>
                        {/* login register > md */}
                        <div className="flex items-center gap-x-1 max-md:hidden">
                            <Link href={`/${lang}/login`} className="nav-link">
                                <IoPerson />
                                <span className="capitalize">{translate("login")}</span>
                            </Link>

                            <span className="capitalize">{translate('OR')}</span>

                            <Link href={`/${lang}/register`} className="nav-link">
                                <FaPlus />
                                <span className="capitalize">{translate("register")}</span>
                            </Link>
                        </div>

                        {/* login & register < md */}
                        <Menu as={`div`} className="md:hidden">
                            <MenuButton className="nav-icon-btn">
                                <FaListUl fontSize={20} />
                            </MenuButton>

                            <MenuItems transition anchor="bottom end" className={`nav-menu`}>
                                <MenuItem as={Link} href={`/${lang}/login`} className="px-3 py-1 hover:bg-blue-200 capitalize text-center">
                                    {translate('login')}
                                </MenuItem>

                                <MenuItem as={Link} href={`/${lang}/register`} className="px-3 py-1 hover:bg-blue-200 capitalize text-center">
                                    {translate('register')}
                                </MenuItem>
                            </MenuItems>
                        </Menu>
                    </Fragment>
            }
        </div>
    )
}

export default withStore(ClientHeader)