"use client"

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { dictReducer, langReducer, userReducer, socketReducer, announcementsReducer } from './reducers';
// import storage from 'redux-persist/lib/storage'
import localforage from 'localforage';

// Configure persistence
const langPersistConfig = {
    key: 'lang',
    storage: localforage,
};

const dictPersistConfig = {
    key: 'dict',
    storage: localforage,
};

// Persisted reducers
const persistedLangReducer = persistReducer(langPersistConfig, langReducer);
const persistedDictReducer = persistReducer(dictPersistConfig, dictReducer);

// Root reducer
const rootReducer = combineReducers({
    lang: persistedLangReducer,
    dict: persistedDictReducer,
    user: userReducer,
    socket: socketReducer,
    announcements: announcementsReducer,
});


// Configure the store
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export const persistor = persistStore(store)
