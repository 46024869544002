"use client"

import Swal from "sweetalert2"
import { BACKEND_ROOT } from "@constants"
import { Fragment, useEffect, useState } from "react"
import { AxiosRequest, dev_console_log } from "@utils"
import { FaComment } from "@react-icons/all-files/fa/FaComment"
import { FaHeart } from "@react-icons/all-files/fa/FaHeart"
import CommentForm from "./CommentForm"
import CommentCard from "./CommentCard"
import { withStore } from "@store-components"
import { clone } from "lodash"
import { useSelector } from "react-redux"
import { useTranslate } from "@hooks"
import { getUser } from "@lib/reducers/userReducer"
import { useParams, useRouter, useSearchParams } from "next/navigation"


const LikesAndComments = ({ p }) => {
    const router = useRouter()
    const searchParams = useSearchParams()
    const { lang } = useParams()

    const user = useSelector(getUser)
    const translate = useTranslate()

    const [post, setPost] = useState(p)
    const [newComment, setNewComment] = useState(false)
    const [toShowComments, setToShowComments] = useState(2)
    
    // hooks
    useEffect(() => {
        const highlightComment = searchParams.get('comment')
        const highlightCommentView = document.getElementById(highlightComment)

        if (
            highlightComment
            && p.comments.map(c => c._id).includes(highlightComment)
        ) {
            if (highlightCommentView) {
                highlightCommentView.scrollIntoView({ behavior: 'smooth' });
            } else {
                const idx = p.comments.findIndex(c => c._id == highlightComment)
                setToShowComments(idx + 1)
            }
        }
    }, [searchParams.get('comment'), toShowComments])


    const handleLikeUnlikePost = async () => {
        if (!user) return router.push(`/${lang}/login`)

        const route = post.likes.includes(user.id) ? 'unlike' : 'like'

        await AxiosRequest.post(`${BACKEND_ROOT}/forum/${route}/${post._id}`)
            .then(res => {
                let updated = clone(post)
                updated.likes = res.data

                setPost(updated)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }


    // comment layer
    const handleNewComment = async (content) => {
        await AxiosRequest.post(`${BACKEND_ROOT}/forum/addComment/${post._id}`, { content })
            .then(res => {
                let updated = clone(post)
                updated.comments.splice(0, 0, res.data)

                setPost(updated)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }


    const handleEditComment = async (content, cId, cIdx) => {
        await AxiosRequest.put(`${BACKEND_ROOT}/forum/updateComment/${cId}`, { content })
            .then(res => {
                let updated = clone(post)
                updated.comments[cIdx].content = res.data.content

                setPost(updated)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }


    const handleDeleteComment = (cId, cIdx) => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm delete!",
            showConfirmButton: false,
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: true,
            denyButtonText: "Delete",
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.delete(`${BACKEND_ROOT}/forum/deleteComment/${cId}`)
                    .then(res => {
                        if (res.status == 200) {
                            let updated = clone(post)
                            updated.comments.splice(cIdx, 1)

                            setPost(updated)
                        }
                    }).catch(err => {
                        dev_console_log(err.message || err.code);
                    })
            }
        }).catch(err => {
            dev_console_log(err.message || err.code)
        })
    }


    const handleReportComment = (cId, cIdx) => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm report!",
            showConfirmButton: false,
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: true,
            denyButtonText: "Report",
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.post(`${BACKEND_ROOT}/forum/report/Comment/${cId}`)
                    .then(res => {
                        if (res.status == 201) {
                            let updated = clone(post)
                            updated.comments.splice(cIdx, 1)

                            setPost(updated)
                        }
                    }).catch(err => {
                        dev_console_log(err.message || err.code);
                    })
            }
        }).catch(err => {
            dev_console_log(err.message || err.code)
        })
    }


    // reply layer
    const handleNewReply = async (content, cId, cIdx) => {
        await AxiosRequest.post(`${BACKEND_ROOT}/forum/replyComment/${cId}`, { content })
            .then(res => {
                let updated = clone(post)
                updated.comments[cIdx].replies.splice(0, 0, res.data)

                setPost(updated)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }


    const handleEditReply = async (content, cIdx, rId, rIdx) => {
        await AxiosRequest.put(`${BACKEND_ROOT}/forum/updateReply/${rId}`, { content })
            .then(res => {
                let updated = clone(post)
                updated.comments[cIdx].replies[rIdx].content = res.data.content

                setPost(updated)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }


    const handleDeleteReply = (cIdx, rId, rIdx) => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm delete!",
            showConfirmButton: false,
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: true,
            denyButtonText: "Delete",
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.delete(`${BACKEND_ROOT}/forum/deleteReply/${rId}`)
                    .then(res => {
                        if (res.status == 200) {
                            let updated = clone(post)
                            updated.comments[cIdx].replies.splice(rIdx, 1)

                            setPost(updated)
                        }
                    }).catch(err => {
                        dev_console_log(err.message || err.code);
                    })
            }
        }).catch(err => {
            dev_console_log(err.message || err.code)
        })
    }


    const handleReportReply = (cIdx, rId, rIdx) => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm report!",
            showConfirmButton: false,
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: true,
            denyButtonText: "Report",
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.post(`${BACKEND_ROOT}/forum/report/Reply/${rId}`)
                    .then(res => {
                        if (res.status == 201) {
                            let updated = clone(post)
                            updated.comments[cIdx].replies.splice(rIdx, 1)

                            setPost(updated)
                        }
                    }).catch(err => {
                        dev_console_log(err.message || err.code);
                    })
            }
        }).catch(err => {
            dev_console_log(err.message || err.code)
        })
    }


    return (
        <Fragment>
            <div className="flex gap-x-2 justify-between text-sm">
                <div className="flex items-center gap-x-2">
                    <button onClick={() => handleLikeUnlikePost()} className={`capitalize w-[100px] flex justify-center gap-x-1 items-center px-4 py-2 border rounded-full transition-colors duration-300 ${post.likes.includes(user?.id) ? 'bg-gray-900 hover:bg-gray-800 text-gray-50 border-gray-900' : 'bg-gray-50 hover:bg-gray-100 text-gray-900 border-gray-300'}`}>
                        <FaHeart />
                        {translate(post.likes.includes(user?.id) ? `Liked` : `Like`)}
                    </button>
                    <button onClick={() => {
                        if (!user) return router.push(`/${lang}/login`)

                        setNewComment(true)
                    }} className="capitalize flex gap-x-1 items-center px-4 py-2 border-gray-300 border rounded-full hover:bg-gray-200 transition-colors duration-300 bg-gray-50">
                        <FaComment />
                        {translate(`Comment`)}
                    </button>
                </div>

                <div className="flex items-center gap-x-2 text-gray-500 italic">
                    <span>{post.likes?.length} {translate(`like(s),`)}</span>
                    <span>{post.comments?.length} {translate(`comment(s)`)}</span>
                </div>
            </div>

            <div className="flex flex-col w-full gap-y-2">
                {
                    newComment &&
                    <CommentForm
                        handleFormAction={handleNewComment}
                        closeForm={() => setNewComment(false)}
                        postId={post._id}
                    />}
                {
                    post.comments.length == 0 ?
                        <h1 className="text-center font-semibold text-gray-500">{translate(`This post have no comments, yet!`)}</h1>
                        :
                        post.comments.slice(0, toShowComments).map((c, cIdx) => {
                            return (
                                <CommentCard
                                    key={cIdx}
                                    c={c}

                                    handleEditComment={async (content) => await handleEditComment(content, c._id, cIdx)}
                                    handleDeleteComment={() => handleDeleteComment(c._id, cIdx)}
                                    handleReportComment={() => handleReportComment(c._id, cIdx)}

                                    handleNewReply={(content) => handleNewReply(content, c._id, cIdx)}
                                    handleEditReply={(content, rId, rIdx) => handleEditReply(content, cIdx, rId, rIdx)}
                                    handleDeleteReply={(rId, rIdx) => handleDeleteReply(cIdx, rId, rIdx)}
                                    handleReportReply={(rId, rIdx) => handleReportReply(cIdx, rId, rIdx)}

                                    user={user}
                                    courseSeoTxt={post.course.seoTxt}
                                />
                            )
                        })
                }
                {
                    toShowComments < post.comments.length ?
                        <button className="text-blue-500 hover:underline text-sm italic w-fit mx-auto capitalize" onClick={() => {
                            if (!window.location.pathname.includes('forum')) return router.push(`/${lang}/course/${post.course.seoTxt}/forum/${post.seoTxt}`);

                            setToShowComments(toShowComments + 2)
                        }}>
                            {translate(`More Comments`)}
                        </button>
                        : toShowComments > 2 &&
                        <button className="text-blue-500 hover:underline text-sm italic w-fit mx-auto capitalize" onClick={() => setToShowComments(2)}>
                            {translate(`Less Comments`)}
                        </button>
                }

            </div>
        </Fragment>
    )
}


export default withStore(LikesAndComments)