"use client"

import { useTranslate } from "@hooks"
import { Fragment, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { FaEye } from "@react-icons/all-files/fa/FaEye"
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash"
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline";
import Select from "react-select"
import { AxiosRequest } from "@utils";
import { BACKEND_ROOT } from "@constants";


const StaffUserForm = ({ initial, handleFormAction }) => {
    const translate = useTranslate()

    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const [showPassword, setShowPassword] = useState({ value: false, confirm: false });
    const [loading, setLoading] = useState(false)

    const [coursesOptions, setCoursesOptions] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])

    useEffect(() => {
        Promise.all([
            AxiosRequest.get(`${BACKEND_ROOT}/course/getCoursesOptions`).then(res => {
                const courses = res.data
                setCoursesOptions(courses.map(c => ({ value: c._id, label: c.title })))
            }).catch(err => {
                console.info(err.message)
            }),

            // get user enrolled courses
            ...(initial ? [
                AxiosRequest.get(`${BACKEND_ROOT}/user/getTeacherCourses/${initial?._id}`).then(res => {
                    const courses = res.data
                    setSelectedCourses(courses.map(c => ({ value: c._id, label: c.title })))
                }).catch(err => {
                    console.info(err.message)
                })
            ] : [])
        ])

    }, []);

    const roleOptions = ['Admin', "Editor", "Teacher"].map(option => ({ value: option, label: translate(option ) }));
    const required = {
        "role": translate("User Role" ),
        "firstName": translate("First Name" ),
        "lastName": translate("Last Name" ),
        "email": translate("Email" ),
        "password": translate("Password" ),
        "confirmPassword": translate("Confirm Password" )
    }

    const validationRules = {
        firstName: { invalid: (value) => value.length < 3, error: "Must be 3 letters at least!" },
        lastName: { invalid: (value) => value.length < 3, error: "Must be 3 letters at least!" },
        email: { invalid: (value) => !/^.+\@.+\..+$/.test(value), error: "Please, Enter a valid email!" },
        password: { invalid: (value) => !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(value), error: "Invalid Password!" },
        confirmPassword: { invalid: (value) => formData.password != value, error: "Must match Password!" },
    }


    const validateField = (fieldName, fieldValue) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[fieldName]

        if (validationRules[fieldName].invalid(fieldValue)) {
            updatedErrors[fieldName] = validationRules[fieldName].error
        }

        setErrors(updatedErrors)
    }


    const handleChange = (e) => {
        const fieldName = e.target.id
        const fieldValue = e.target.value

        setFormData({
            ...formData,
            [fieldName]: fieldValue,
        })

        validateField(fieldName, fieldValue)
    }

    
    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true);
        await handleFormAction({ ...formData, coursesIds: selectedCourses.map(c => c.value) }, setErrors, required);
        setLoading(false);
    }
    
    return (
        <div className="flex flex-col justify-center items-center gap-y-2 ">
            <h1 className="text-2xl font-semibold text-blue-500">{translate(initial ? `Update User Details` : "Create New User" )}</h1>

            <form className="flex flex-col gap-y-2 p-4 rounded border border-gray-300 bg-gray-100 w-full" onSubmit={handleSubmit}>
                {/* firstName & lastName */}
                <div className="flex gap-x-4 max-md:flex-col gap-y-2 text-start">
                    {/* firstName */}
                    <div className="flex flex-col w-full">
                        <label htmlFor="firstName">{required.firstName}<span className="text-red-500 text-sm"> *</span></label>
                        <input value={formData.firstName || initial?.firstName || ""} id="firstName" type="text" className="input-bar" placeholder={translate("Enter your first name.." )} onChange={handleChange} />
                        {
                            errors.firstName &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.firstName )}
                            </small>
                        }
                    </div>

                    {/* lastName */}
                    <div className="flex flex-col w-full">
                        <label htmlFor="lastName">{required.lastName}<span className="text-red-500 text-sm"> *</span></label>
                        <input value={formData.lastName || initial?.lastName || ""} id="lastName" type="text" className="input-bar" placeholder={translate("Enter your last name.." )} onChange={handleChange} />
                        {
                            errors.lastName &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.lastName )}
                            </small>
                        }
                    </div>
                </div>

                {/* role */}
                <div className="flex flex-col w-full text-start">
                    <label htmlFor="role" className="text-start">{required.role}<span className="text-red-500 text-sm"> *</span></label>
                    <Select
                        id='role'
                        options={roleOptions}
                        isSearchable
                        classNames={{ control: () => "select-input" }}
                        value={roleOptions.find(op => op.value == (formData.role || initial?.role)) || ""}
                        onChange={op => {
                            let updatedErrors = { ...errors }
                            delete updatedErrors['role']
                            setErrors(updatedErrors)

                            setFormData({ ...formData, role: op.value })
                        }}
                        placeholder={translate("Choose new user role.." )}
                    />
                    {
                        errors.role &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.role )}
                        </small>
                    }
                </div>

                {/* email */}
                <div className="flex flex-col w-full text-start">
                    <label htmlFor="email">{required.email}<span className="text-red-500 text-sm"> *</span></label>
                    <input value={formData.email || initial?.email || ""} id="email" type="text" className="input-bar" placeholder="user10@example.com" onChange={handleChange} />
                    {
                        errors.email &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.email )}
                        </small>
                    }
                </div>

                {
                    !initial &&
                    <Fragment>
                        {/* password */}
                        <div className="flex flex-col w-full text-start">
                            <label htmlFor="password">{required.password}<span className="text-red-500 text-sm"> *</span></label>
                            <div className="relative">
                                <input
                                    id="password"
                                    type={showPassword.value ? 'text' : 'password'}
                                    className="input-bar"
                                    placeholder={translate("Enter your password.." )}
                                    onChange={handleChange}
                                />
                                <button
                                    type='button' className='p-1 absolute inset-y-0 end-2.5'
                                    onClick={() => setShowPassword({ ...showPassword, value: !showPassword.value })}>
                                    {
                                        showPassword.value ?
                                            <FaEye className="text-lg" />
                                            :
                                            <FaEyeSlash className="text-lg" />
                                    }
                                </button>
                            </div>
                            {
                                errors.password &&
                                <div className="flex-col">
                                    <small className='text-red-700 flex items-center gap-1'>
                                        <MdErrorOutline /> {translate(errors.password )}
                                    </small>

                                    <small className='text-red-700 flex items-center gap-1'>
                                        <MdErrorOutline /> {translate(`Must be 8 characters at least!` )}
                                    </small>

                                    <small className='text-red-700 flex items-center gap-1'>
                                        <MdErrorOutline /> {translate("Must contain 1 english letter at least!" )}
                                    </small>

                                    <small className='text-red-700 flex items-center gap-1'>
                                        <MdErrorOutline /> {translate(`Must contain 1 number at least!` )}
                                    </small>

                                </div>
                            }
                        </div>

                        {/* confirmPassword */}
                        <div className="flex flex-col w-full text-start">
                            <label htmlFor="confirmPassword">{required.confirmPassword}<span className="text-red-500 text-sm"> *</span></label>
                            <div className="relative">
                                <input
                                    id="confirmPassword"
                                    type={showPassword.confirm ? 'text' : 'password'}
                                    className="input-bar"
                                    placeholder={translate("Enter your password again!" )}
                                    onChange={handleChange}
                                />
                                <button
                                    type='button' className='p-1 absolute inset-y-0 end-2.5'
                                    onClick={() => setShowPassword({ ...showPassword, confirm: !showPassword.confirm })}>
                                    {
                                        showPassword.confirm ?
                                            <FaEye className="text-lg" />
                                            :
                                            <FaEyeSlash className="text-lg" />
                                    }
                                </button>
                            </div>
                            {
                                errors.confirmPassword &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.confirmPassword )}
                                </small>
                            }
                        </div>
                    </Fragment>
                }

                {/* courses */}
                <div className="flex flex-col text-start">
                    <h2 className="">{translate("Assign Courses To User as Teacher")} <span className="text-gray-500 text-sm italic">({translate("Optional")})</span></h2>
                    <Select
                        options={coursesOptions}
                        isMulti
                        isSearchable
                        value={selectedCourses}
                        onChange={courses => {
                            setErrors(prev => {
                                delete prev.courses

                                return prev
                            })
                            setSelectedCourses(courses)
                        }}
                        placeholder={translate("Choose courses...")}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 100 }),
                        }}
                    />

                    {
                        errors.courses &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.courses)}
                        </small>
                    }
                </div>

                {/* create button */}
                <button className="submit-btn w-full mt-4" disabled={loading || Object.values(errors).some(Boolean)}>
                    {
                        loading ?
                            <span className='flex justify-center text-gray-50 text-[24px]'>
                                <AiOutlineLoading3Quarters className='animate-spin' />
                            </span>
                            : <span className='capitalize'>{translate(initial ? 'Save Updates' : 'Create User' )}</span>
                    }
                </button>
            </form>
        </div>
    )
}

export default StaffUserForm