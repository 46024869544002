"use client"

import { Fragment, useEffect, useState } from "react"
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import PostCard from "./PostCard"
import { useIntersectionObserver } from "@hooks"
import { AxiosRequest , dev_console_log } from "@utils"
import { BACKEND_ROOT } from "@constants"

const LoadMorePosts = ({ courseId, skip, lang, user }) => {
    const [loadMoreRef, isIntersecting] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    })

    const RenderPosts = () => {
        const [posts, setPosts] = useState(null)

        useEffect(() => {
            AxiosRequest.get(`${BACKEND_ROOT}/forum/listPosts/${courseId}`, {
                params: { limit: 5, skip, }
            }).then(res => {
                setPosts(res.data)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
        }, [])

        return !posts ? <AiOutlineLoading3Quarters className="animate-spin text-[50px] text-blue-500 mx-auto" />
            :
            <Fragment>
                {
                    posts.map((p, pIdx) =>
                        <PostCard
                            key={pIdx}
                            p={p}
                            pIdx={pIdx}
                            initial={posts}
                            user={user}
                            lang={lang}
                        />
                    )
                }

                {posts.length >= 5 && <LoadMorePosts courseId={courseId} skip={skip + posts.length} />}
            </Fragment>

    }


    return (
        <div ref={loadMoreRef}>
            {isIntersecting && <RenderPosts />}
        </div>
    )
}

export default LoadMorePosts