"use client"

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { getTimeAgo } from "@utils"
import { Fragment, useEffect, useState } from "react"
import { IoEllipsisVertical } from "@react-icons/all-files/io5/IoEllipsisVertical"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { MdEdit } from "@react-icons/all-files/md/MdEdit"
import { MdReport } from "@react-icons/all-files/md/MdReport"
import CommentForm from "./CommentForm"
import { useRouter, useSearchParams } from "next/navigation"
import ReplyCard from "./ReplyCard"
import { useTranslate } from "@hooks"
import { useSelector } from "react-redux"
import { getLang } from "@lib/reducers/langReducer"
import BadgeSingleComp from "@client-components/BadgeSingleComp";


const CommentCard = ({
    c,
    handleEditComment,
    handleDeleteComment,
    handleReportComment,
    handleNewReply,
    handleEditReply,
    handleDeleteReply,
    handleReportReply,

    user,
    courseSeoTxt,
}) => {
    const translate = useTranslate()
    const router = useRouter()

    const [editComment, setEditComment] = useState(false)
    const [toShowReplies, setToShowReplies] = useState(1)
    const [newReply, setNewReply] = useState(false)

    const lang = useSelector(getLang)
    const searchParams = useSearchParams()


    useEffect(() => {
        const highlightComment = searchParams.get('comment')
        const highlightCommentView = document.getElementById(highlightComment)

        const highlightReply = searchParams.get('reply')
        const highlightReplyView = document.getElementById(highlightReply)

        if (
            highlightComment
            && highlightCommentView
            && highlightComment == c._id
            && highlightReply
        ) {
            if (highlightReplyView) {
                highlightReplyView.scrollIntoView({ behavior: 'smooth' });
            } else {
                const idx = c.replies.findIndex(r => r._id == highlightReply)
                setToShowReplies(idx + 1)
            }
        }
    }, [searchParams?.get('comment'), searchParams?.get('reply'), toShowReplies])


    return editComment ?
        <CommentForm
            initial={c}
            handleFormAction={handleEditComment}
            closeForm={() => setEditComment(false)}
            postId={c.post}
        />
        :
        <div className="flex gap-x-2 justify-between items-start">
            <div className="flex flex-col w-full">
                {/* details */}
                <div className="flex gap-x-2 justify-between items-center text-gray-500">
                    <div>
                        <h2 className="font-semibold">{c.user.firstName} {c.user.lastName}</h2>
                        {
                            (() => {
                                const tmp_BadgesArr = c?.user?.BadgesArr;
                                // dev_console_log("tmp_BadgesArr");
                                // dev_console_log(tmp_BadgesArr);
                                if (!(tmp_BadgesArr?.length)) {
                                    return null;
                                }
                                return (
                                    <div
                                        style={{
                                            overflowY: "auto",
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                        }}
                                    >
                                        {
                                            tmp_BadgesArr.map((badge,index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: "50px",
                                                        }}
                                                    >
                                                        <BadgeSingleComp
                                                            parent_is={"parent_is"}
                                                            badge={badge}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                )
                            })()
                        }
                    </div>
                    <span className="italic text-xs text-gray-500">{getTimeAgo(c.createdAt)}</span>
                </div>

                {/* content */}
                <p id={c._id} className={`${c._id == searchParams.get('comment') ? 'bg-green-200' : window.location.pathname.includes(c.post.seoTxt) ? 'bg-gray-100' : 'bg-gray-50'} border border-gray-300 p-2 rounded-lg`}>{c.content}</p>
                <button onClick={() => {
                    if (!user) return router.push(`/${lang}/login`)

                    setNewReply(true)
                }} className="hover:underline text-blue-500 text-sm w-fit capitalize">{translate(`Reply`)}</button>

                <div className={`flex flex-col scale-95`}>
                    {
                        newReply &&
                        <CommentForm
                            handleFormAction={handleNewReply}
                            closeForm={() => setNewReply(false)}
                            postId={c.post}
                        />
                    }
                    {
                        c.replies.length > 0 &&
                        <Fragment>
                            {
                                c.replies.slice(0, toShowReplies).map((r, rIdx) => {

                                    return (
                                        <ReplyCard
                                            key={rIdx}
                                            r={r}
                                            handleEditReply={(content) => handleEditReply(content, r._id, rIdx)}
                                            handleDeleteReply={() => handleDeleteReply(r._id, rIdx)}
                                            handleReportReply={() => handleReportReply(r._id, rIdx)}

                                            user={user}
                                            courseSeoTxt={courseSeoTxt}
                                            postSeoTxt={c.post.seoTxt}
                                        />
                                    )
                                })
                            }

                            {
                                toShowReplies < c.replies.length ?
                                    <button className="text-blue-500 hover:underline text-sm italic w-fit mx-auto capitalize" onClick={() => {
                                        if (!window.location.pathname.includes('forum')) return router.push(`/${lang}/course/${courseSeoTxt}/forum/${c.post.seoTxt}`);

                                        setToShowReplies(toShowReplies + 2)
                                    }}>
                                        {translate(`More Replies`)}
                                    </button>
                                    : toShowReplies > 1 &&
                                    <button className="text-blue-500 hover:underline text-sm italic w-fit mx-auto capitalize" onClick={() => setToShowReplies(1)}>
                                        {translate(`Less Replies`)}
                                    </button>
                            }
                        </Fragment>
                    }
                </div>
            </div>

            {
                user &&
                <Menu>
                    <MenuButton className="text-gray-500 p-2 rounded-full hover:bg-gray-200 transition-colors duration-300 text-[18px]">
                        <IoEllipsisVertical />
                    </MenuButton>
                    <MenuItems transition anchor="bottom end" className="menu-3dots">
                        {
                            (c.user._id == user.id || user.permissionLevel > 2) &&
                            <Fragment>
                                <MenuItem as={'button'} onClick={() => setEditComment(true)} className="menu-item-3dots">
                                    <MdEdit />
                                    <span>{translate(`Edit`)}</span>
                                </MenuItem>

                                <MenuItem as={'button'} onClick={handleDeleteComment} className="menu-item-3dots">
                                    <MdDelete />
                                    <span>{translate(`Delete`)}</span>
                                </MenuItem>
                            </Fragment>
                        }

                        {
                            (c.user._id != user.id || user.permissionLevel > 2) &&
                            <MenuItem as={'button'} onClick={handleReportComment} className="menu-item-3dots">
                                <MdReport />
                                <span>{translate(`Report`)}</span>
                            </MenuItem>
                        }
                    </MenuItems>
                </Menu>
            }
        </div>
}

export default CommentCard