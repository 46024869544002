"use client"

import { useTranslate } from "@hooks";
import { useState } from "react";
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import {FaPlus} from "@react-icons/all-files/fa/FaPlus";
import {MdDelete} from "@react-icons/all-files/md/MdDelete";
import {MdErrorOutline} from "@react-icons/all-files/md/MdErrorOutline";

const LearningTimesForm = ({ initial, handleFormAction }) => {
    const translate = useTranslate()

    const [learningTimes, setLearningTimes] = useState(initial || [])

    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        await handleFormAction({ learningTimes })
        setLoading(false)
    }

    const handleAdd = () => {
        const updated = [...learningTimes]
        updated.push({ repeatDays: [], alarmTime: "" })
        setLearningTimes(updated)
        setErrors({
            ...errors,
            [`lt-${updated.length - 1}-time`]: true,
            [`lt-${updated.length - 1}-days`]: true,
        })
    }

    const handleDelete = (idx) => {
        const updated = [...learningTimes]
        updated.splice(idx, 1);
        setLearningTimes(updated)

        const updatederrors = { ...errors }
        delete updatederrors[`lt-${idx}-time`]
        delete updatederrors[`lt-${idx}-days`]
        setErrors(updatederrors)
    }

    const handleTimeChange = (e, idx) => {
        setErrors({
            ...errors,
            [e.target.id]: false
        })

        const updated = [...learningTimes]
        updated[idx].alarmTime = e.target.value

        setLearningTimes(updated)
        if (e.target.value == "") setErrors({ ...errors, [e.target.id]: true })
    }

    const handleDayCheck = (e, lt, idx, day) => {
        setErrors({
            ...errors,
            [`lt-${idx}-days`]: false
        })

        const updated = [...learningTimes]

        if (e.target.checked) {
            updated[idx].repeatDays.push(day)
            setLearningTimes(updated)
        } else {
            const ltIdx = lt.repeatDays.findIndex(item => item == day)
            updated[idx].repeatDays.splice(ltIdx, 1)
            setLearningTimes(updated)
        }

        if (updated[idx].repeatDays.length == 0) setErrors({ ...errors, [`lt-${idx}-days`]: true })
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 p-8">
            <h1 className="font-semibold text-2xl text-blue-500 text-start">
                {translate(`Please, Enter your learning times:`)}
            </h1>

            <div className="flex flex-wrap gap-4">
                {
                    learningTimes?.map((lt, idx) => {

                        return (
                            <div key={idx} className="w-[240px] h-[320px] mx-auto flex flex-col rounded border-2 p-4 gap-y-2 border-dashed border-gray-300 bg-gray-100">
                                {/* time idx */}
                                <div className="flex gap-x-1 justify-between">
                                    <span className="font-semibold">{translate(`Time`)} { idx + 1}</span>
                                    <button type="button" className="danger-icon-btn" onClick={() => handleDelete(idx)}><MdDelete /></button>
                                </div>

                                {/* time input */}
                                <div className="flex flex-col text-sm">
                                    <label htmlFor={`lt-${idx}-time`} className="text-start">
                                        {translate(`Alarm Time:`)}<span className="text-red-500"> *</span>
                                    </label>
                                    <input
                                        id={`lt-${idx}-time`}
                                        className="tiny-input" type="time" value={lt.alarmTime} placeholder="00:00"
                                        onChange={e => handleTimeChange(e, idx)} />
                                    {
                                        errors[`lt-${idx}-time`] &&
                                        <small className='text-red-700 flex items-center gap-1 truncate'>
                                            <MdErrorOutline /> {translate("Alarm Time is required!")}
                                        </small>
                                    }
                                </div>

                                {/* days checkboxes */}
                                <div className="flex flex-col text-sm">
                                    <span className="text-start">
                                        {translate(`Repeat Days:`)}<span className="text-red-500"> *</span>
                                    </span>

                                    <div className="flex flex-col" id={`lt-${idx}-days`}>
                                        {
                                            ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day, dayIdx) => {

                                                return (
                                                    <div key={dayIdx} className="flex items-center gap-x-1">

                                                        <input
                                                            type="checkbox"
                                                            id={`lt-${idx}-${day}`}
                                                            checked={lt.repeatDays.some(item => item == day)}
                                                            onChange={e => handleDayCheck(e, lt, idx, day)}
                                                        />
                                                        <label htmlFor={`lt-${idx}-${day}`}>{translate(day)}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {
                                        errors[`lt-${idx}-days`] &&
                                        <small className='text-red-700 flex items-center gap-1 truncate'>
                                            <MdErrorOutline /> {translate("You have to choose 1 day at least!")}
                                        </small>
                                    }
                                </div>
                            </div>
                        )
                    })
                }

                <button
                    type="button"
                    className="w-[240px] h-[320px] mx-auto text-gray-400 rounded border-2 p-4 border-dashed border-gray-300 bg-gray-100 hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center gap-x-2"
                    onClick={handleAdd}
                >
                    <FaPlus />
                    {translate("Click to add time")}
                </button>
            </div>

            <button
                className="submit-btn max-w-xl mx-auto w-full"
                disabled={
                    loading
                    || Object.values(errors).some(v => v)
                    || learningTimes.length == 0
                }
            >
                {
                    loading ?
                        <span className='flex justify-center text-gray-50 text-[24px]'>
                            <AiOutlineLoading3Quarters className='animate-spin' />
                        </span>
                        : <span>{translate(`${initial ? 'Save Updates' : 'Enroll Course'}`)}</span>
                }

            </button>
        </form>
    )
}


export default LearningTimesForm