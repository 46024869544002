"use client"

import { BACKEND_ROOT } from "@constants"
import { Switch } from "@headlessui/react"
import { useTranslate } from "@hooks"
import { AxiosRequest, dev_console_log, loadJsonCourse, formatKeyText } from "@utils"
import { Fragment, useEffect, useMemo, useState } from "react"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { FaGraduationCap } from "@react-icons/all-files/fa/FaGraduationCap"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline"
import Select from "react-select"
import { FaUpload } from "@react-icons/all-files/fa/FaUpload";
import { FaImage } from "@react-icons/all-files/fa/FaImage";


const CourseForm = ({ initial, handleFormAction, givenOptions }) => {
    const translate = useTranslate()

    const { image, description, ...rest } = initial || {}
   
    const [formData, setFormData] = useState(rest?.modules ? rest : { type: 'academic', modules: [], learningOutcomes: [] })
    const [imageSrc, setImageSrc] = useState(image || null)
    const [errors, setErrors] = useState({
        ...(formData.learningOutcomes.length == 0 && {
            learningOutcomes: "You have to add 1 learning outcome at least!"
        }),
        ...(formData.modules.length == 0 && {
            modules: "You have to add 1 module at least!"
        }),
    })
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState(givenOptions)

 
    const required = useMemo(() => {
        const mainFields = {
            "title": translate("Course Title"),
            "learningOutcomes": translate("Learning Outcomes"),
            "modules": translate("Modules"),
            ...(!image && { "file": translate("Course Image") }),
        }

        const academicFields = {
            "educationalSystem": translate("Educational System"),
            "gradeLevel": translate("Grade Level"),
        }

        const professionalFields = {
            "pathway": translate("Course Pathway"),
            "order": translate("Order in Pathway"),
        }

        return formData.type == 'academic' ? { ...mainFields, ...academicFields } : { ...mainFields, ...professionalFields }
    }, [formData.type])

    useEffect(() => {
        const getOptions = async () => {
            try {
                const educationalSystems = await AxiosRequest.get(`${BACKEND_ROOT}/constants/allRecords/EducationalSystem`)
                const gradeLevels = await AxiosRequest.get(`${BACKEND_ROOT}/constants/allRecords/GradeLevel`)
                const pathways = await AxiosRequest.get(`${BACKEND_ROOT}/constants/allRecords/Pathway`)

                setOptions({
                    educationalSystems: educationalSystems.data.map(option => ({ value: option._id, label: translate(option.name) })),
                    gradeLevels: gradeLevels.data.map(option => ({ value: option._id, label: translate(option.name) })),
                    pathways: pathways.data.map(option => ({ value: option._id, label: translate(option.name) })),
                })
            } catch (error) {
                dev_console_log(error);
            }
        }
        getOptions()
    }, [])

    const validateField = (fieldName, fieldValue) => {
        let isInvalid = false
        let updatedErrors = { ...errors }

        if (!Boolean(fieldValue)) {
            updatedErrors[fieldName] = `${formatKeyText(fieldName)} field is required!`
            isInvalid = true
        } else {
            delete updatedErrors[fieldName]
        }

        setErrors(updatedErrors)

        return isInvalid
    }

    const handleChange = (e) => {
        const fieldName = e.target.id
        const fieldValue = e.target.value

        setFormData({
            ...formData,
            [fieldName]: fieldValue,
        })

        validateField(fieldName, fieldValue)
    }

    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true);
        await handleFormAction(formData, setErrors, required);
        setLoading(false);
    }

    const handleModuleChange = (e, moduleIdx) => {
        const fieldName = e.target.id.split(`module-${moduleIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-${fieldName}`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue) {
            updatedErrors[`module-${moduleIdx}-${fieldName}`] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} field is required!`
        }
        setErrors(updatedErrors)
    }

    const handleLessonChange = (e, moduleIdx, lessonIdx) => {
        const fieldName = e.target.id.split(`module-${moduleIdx}-lesson-${lessonIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-${fieldName}`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue && !['otherInfo'].includes(fieldName)) {
            updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-${fieldName}`] = `${formatKeyText(fieldName)} field is required!`
        }
        setErrors(updatedErrors)
    }

    const addNewModule = () => {
        let updatedErrors = { ...errors }
        delete updatedErrors.modules

        let updatedFormData = { ...formData }
        updatedFormData.modules.push({ number: "", name: "", lessons: [] })
        setFormData(updatedFormData)

        const moduleIdx = updatedFormData.modules.length - 1
        updatedErrors[`module-${moduleIdx}-number`] = "Number field is required"
        updatedErrors[`module-${moduleIdx}-name`] = "Name field is required"
        updatedErrors[`module-${moduleIdx}-lessons`] = "You have to add 1 lesson at least!"
        setErrors(updatedErrors)
    }

    const addNewLesson = (moduleIdx) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-lessons`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons.push({ number: "", name: "", learningOutcomes: [], content: "", sampleQuestions: [], otherInfo: "" })
        setFormData(updatedFormData)

        const lessonIdx = updatedFormData.modules[moduleIdx].lessons.length - 1
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-number`] = "Number field is required"
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-name`] = "Name field is required"
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-content`] = "Content field is required"
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] = "You have to add 1 learning outcome at least!"
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] = "You have to add 1 sample questions at least!"
        setErrors(updatedErrors)
    }

    const deleteModule = (moduleIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.modules.splice(moduleIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`module-${moduleIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`module-`)) {
                let keyTokens = key.split(`module-`)[1].split('-')
                let kModuleIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kModuleIdx > moduleIdx) {
                    updatedErrors[`module-${kModuleIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.modules.length == 0) {
            updatedErrors.modules = "You have to add 1 module at least!"
        }
        setErrors(updatedErrors)
    }

    const deleteLesson = (moduleIdx, lessonIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons.splice(lessonIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`module-${moduleIdx}-lesson-${lessonIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`module-${moduleIdx}-lesson-`)) {
                let keyTokens = key.split(`module-${moduleIdx}-lesson-`)[1].split('-')
                let kLessonIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kLessonIdx > lessonIdx) {
                    updatedErrors[`module-${moduleIdx}-lesson-${kLessonIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.modules[moduleIdx].lessons.length == 0) {
            updatedErrors[`module-${moduleIdx}-lessons`] = "You have to add 1 lesson at least!"
        }
        setErrors(updatedErrors)
    }

    const handleLessonLOFieldChange = (e, moduleIdx, lessonIdx, outcomeIdx) => {
        const fieldName = e.target.id.split(`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-${fieldName}`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx].learningOutcomes[outcomeIdx] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue) {
            updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-${fieldName}`] = `${formatKeyText(fieldName)} field is required!`
        }
        setErrors(updatedErrors)
    }

    const addNewLessonLO = (moduleIdx, lessonIdx) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx].learningOutcomes.push("")
        setFormData(updatedFormData)

        const outcomeIdx = updatedFormData.modules[moduleIdx].lessons[lessonIdx].learningOutcomes.length - 1
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-statement`] = "Statement field is required"
        setErrors(updatedErrors)
    }

    const deleteLessonLO = (moduleIdx, lessonIdx, outcomeIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx].learningOutcomes.splice(outcomeIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-`)) {
                let keyTokens = key.split(`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-`)[1].split('-')
                let kOutcomeIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kOutcomeIdx > outcomeIdx) {
                    updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${kOutcomeIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.modules[moduleIdx].lessons[lessonIdx].learningOutcomes.length == 0) {
            updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] = "You have to add 1 learning outcome at least!"
        }
        setErrors(updatedErrors)
    }

    const handleSampleQuestionsChange = (e, moduleIdx, lessonIdx, questionsIdx) => {
        const fieldName = e.target.id.split(`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-${fieldName}`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx].sampleQuestions[questionsIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue) {
            updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-${fieldName}`] = `${formatKeyText(fieldName)} field is required!`
        }
        setErrors(updatedErrors)
    }

    const addNewSampleQuestions = (moduleIdx, lessonIdx) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`]

        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx].sampleQuestions.push({ type: "", questions: "" })
        setFormData(updatedFormData)

        const sampleQuestionsIdx = updatedFormData.modules[moduleIdx].lessons[lessonIdx].sampleQuestions.length - 1
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${sampleQuestionsIdx}-type`] = "type field is required"
        updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${sampleQuestionsIdx}-questions`] = "questions field is required"
        setErrors(updatedErrors)
    }

    const deleteSampleQuestions = (moduleIdx, lessonIdx, questionsIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.modules[moduleIdx].lessons[lessonIdx].sampleQuestions.splice(questionsIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-`)) {
                let keyTokens = key.split(`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-`)[1].split('-')
                let kQuestionsIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kQuestionsIdx > questionsIdx) {
                    updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${kQuestionsIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.modules[moduleIdx].lessons[lessonIdx].sampleQuestions.length == 0) {
            updatedErrors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] = "You have to add 1 sample questions at least!"
        }
        setErrors(updatedErrors)
    }

    const handleLOFieldChange = (e, loIdx) => {
        const fieldId = e.target.id
        const fieldName = fieldId.split(`lo-${loIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[fieldId]

        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue) {
            updatedErrors[fieldId] = `${formatKeyText(fieldName)} field is required!`
        }
        setErrors(updatedErrors)
    }

    const deleteLO = (loIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes.splice(loIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`lo-${loIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`lo-`)) {
                let keyTokens = key.split('lo-')[1].split('-')
                let kloIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kloIdx > loIdx) {
                    updatedErrors[`lo-${kloIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.learningOutcomes.length == 0) {
            updatedErrors[`learningOutcomes`] = "You have to add 1 learning outcome at least!"
        }

        setErrors(updatedErrors)
    }

    const handleLOCriteriaChange = (e, loIdx, cIdx) => {
        const fieldName = e.target.id.split(`lo-${loIdx}-pc-${cIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[`lo-${loIdx}-pc-${cIdx}-${fieldName}`]

        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx].performanceCriteria[cIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue) {
            updatedErrors[`lo-${loIdx}-pc-${cIdx}-${fieldName}`] = `${formatKeyText(fieldName)} field is required!`
        }
        setErrors(updatedErrors)
    }

    const addNewLOCriteria = (loIdx) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[`lo-${loIdx}-performanceCriteria`]

        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx].performanceCriteria.push({ description: "" })
        setFormData(updatedFormData)

        updatedErrors[`lo-${loIdx}-pc-${updatedFormData.learningOutcomes[loIdx].performanceCriteria.length - 1}-description`] = "Description field is required"
        setErrors(updatedErrors)
    }

    const deleteLOCriteria = (loIdx, cIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx].performanceCriteria.splice(cIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`lo-${loIdx}-pc-${cIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`lo-${loIdx}-pc-`)) {
                let keyTokens = key.split(`lo-${loIdx}-pc-`)[1].split('-')
                let kcIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kcIdx > cIdx) {
                    updatedErrors[`lo-${loIdx}-pc-${kcIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.learningOutcomes[loIdx].performanceCriteria.length == 0) {
            updatedErrors[`lo-${loIdx}-performanceCriteria`] = "You have to add 1 criteria at least!"
        }
        setErrors(updatedErrors)
    }

    return (
        <div className="flex flex-col justify-center items-center gap-y-2 ">
            <h1 className="text-3xl font-bold text-blue-500">{translate(initial ? `Update` : "Add New Course")} {initial ? formData.title : ""}</h1>

            <form className="flex flex-col gap-y-2 p-4 rounded border border-gray-300 bg-gray-100 w-full" onSubmit={handleSubmit}>
                {/* course type toggler */}
                <div className="flex gap-2 items-center justify-between">
                    <span className="min-w-fit font-bold text-lg">{translate("Academic / Professional")}</span>
                    <Switch
                        checked={formData.type == 'professional'}
                        onChange={() => {
                            let updatedFormData = { ...formData }
                            updatedFormData.type = formData.type == 'professional' ? 'academic' : 'professional'

                            setFormData(updatedFormData)
                        }}
                        className={`bg-gray-200 border-gray-300 flex h-[30px] min-w-[60px] md:min-w-[250px] items-center rounded-full border shadow`}
                    >
                        <div className={`${formData.type == 'professional' ? 'translate-x-full' : 'translate-x-0'} w-1/2 h-full transition-all duration-500 text-gray-50 rounded-full bg-blue-500 hover:bg-blue-400 gap-x-1 flex justify-center items-center`}>
                            <span className={`${formData.type == 'professional' ? 'rotate-0' : '-rotate-[360deg]'} transition-all duration-500`}>
                                {
                                    formData.type == 'professional' ? <FaStar fontSize={20} />
                                        : <FaGraduationCap fontSize={20} />
                                }
                            </span>

                            <span className="text-sm capitalize truncate max-md:hidden">{formData.type == 'professional' ? translate('Professional') : translate('Academic')}</span>
                        </div>
                    </Switch>
                </div>

                {/* load from json */}
                <div className="flex flex-col w-full text-start">
                    <label htmlFor="jsonFile" className="capitalize">{translate('load from json')}?</label>
                    <input id="jsonFile" type="file" accept="application/json" className="input-bar" onChange={async e => {
                        // parse input file data / field name
                        const fieldName = e.target.id
                        const jsonFile = e.target.files[0]

                        // make sure file is valid json
                        const isInvalidJsonFile = validateField(fieldName, jsonFile)
                        if (isInvalidJsonFile) {
                            dev_console_log("invalid file")
                            return;
                        }

                        // make temp url
                        const jsonFilePath = URL.createObjectURL(jsonFile);
                        const loadedJson = await loadJsonCourse(jsonFilePath)

                        // if valid course file, clear errors and set formData
                        if (loadedJson.valid) {
                            let updatedErrors = { ...errors }
                            delete updatedErrors[fieldName]

                            const updatedFormData = {
                                ...formData,
                                title: loadedJson.data.name,
                                gradeLevel: loadedJson.data.grade_level,
                                learningOutcomes: loadedJson.data.learning_outcomes.map(lo => {
                                    return {
                                        ...lo,
                                        performanceCriteria: lo.performanceCriteria.map(pc => {
                                            return {
                                                description: pc
                                            }
                                        }),
                                    }
                                }),
                                modules: loadedJson.data.modules.map(module => ({
                                    number: module.module_number,
                                    name: module.module_name,
                                    lessons: module.lessons.map(lesson => ({
                                        number: lesson.lesson_number,
                                        name: lesson.lesson_name,
                                        learningOutcomes: lesson.learning_outcomes,
                                        content: lesson.content,
                                        sampleQuestions: lesson.sample_questions,
                                        otherInfo: lesson.other_lesson_info,
                                    }))
                                }))
                            }

                            Object.entries(updatedFormData).map(([key, value]) => {
                                const notEmpty = Array.isArray(value) ? value.length > 0 : Boolean(value)

                                if (notEmpty) {
                                    delete updatedErrors[key]
                                }
                            })

                            setFormData(updatedFormData)
                            setErrors(updatedErrors)
                        } else {
                            setErrors({ ...errors, [fieldName]: loadedJson.errors })
                        }
                    }} />
                    {
                        errors.jsonFile &&
                        <small className='text-red-700 flex flex-col gap-y-1'>
                            {
                                Array.isArray(errors.jsonFile) ?
                                    errors.jsonFile.map((errorMessage, idx) => {

                                        return (
                                            <span key={idx} className="flex items-center gap-x-1">
                                                <MdErrorOutline />
                                                {errorMessage}
                                            </span>
                                        )
                                    })
                                    :
                                    <span className="flex items-center gap-x-1">
                                        <MdErrorOutline />
                                        {errors.jsonFile}
                                    </span>
                            }
                        </small>
                    }
                </div>

                {/* title */}
                <div className="flex flex-col w-full">
                    <label htmlFor="title" className="text-start">{required.title}<span className="text-red-500 text-sm"> *</span></label>
                    <input
                        id="title"
                        type="text"
                        className="input-bar"
                        placeholder={translate("Add title for your course")}
                        value={formData.title || ""}
                        onChange={handleChange}
                    />
                    {
                        errors.title &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.title)}
                        </small>
                    }
                </div>

                {/* file */}
                <div className="flex flex-col w-full text-start">
                    <h1 className="capitalize">{translate("Course Image")}<span className="text-red-500 text-sm"> *</span></h1>

                    <div className="relative w-full h-[200px] overflow-hidden">
                        {
                            imageSrc ?
                                <img src={imageSrc} className="w-full h-full object-cover rounded-lg" />
                                :
                                <div className="flex flex-col w-full h-full justify-center items-center text-gray-500 border-2 border-dashed border-gray-400 rounded-lg">
                                    <FaImage className="w-full h-full p-[5%]" />
                                </div>
                        }

                        <label htmlFor="file" className={`rounded-lg ${errors.file || loading ? "opacity-80" : "opacity-0 hover:opacity-80"} ${loading ? "cursor-wait" : "cursor-pointer"} absolute flex flex-col gap-y-2 justify-center items-center bg-black/40 text-gray-50 inset-0 transition-all duration-300`}>
                            {
                                loading ? <AiOutlineLoading3Quarters className="animate-spin w-fit h-1/3" />
                                    : errors.file ?
                                        <Fragment>
                                            <MdErrorOutline className="w-fit h-1/3 text-red-600" />
                                            <span className="text-center font-semibold text-sm uppercase text-red-600">{translate(errors.file)}</span>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <FaUpload className="w-fit h-1/3" />
                                            <span className="capitalize font-semibold">{translate(imageSrc ? 'change' : 'add')} {translate("image")}</span>
                                        </Fragment>
                            }
                        </label>

                        <input id="file" type="file" accept="image/*" hidden disabled={loading} onChange={e => {
                            const file = e.target.files[0];

                            if (!Boolean(file)) return;

                            setFormData({
                                ...formData,
                                file,
                            })

                            const isInvalid = validateField('file', file)
                            if (isInvalid) return;

                            const tempURL = URL.createObjectURL(file);
                            setImageSrc(tempURL);
                        }} />
                        {
                            errors.file &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.file)}
                            </small>
                        }
                    </div>
                </div>

                {
                    formData.type == 'academic' &&
                    <div className="flex gap-x-2 max-md:flex-col">
                        {/* educational system */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="educationalSystem" className="text-start">{required.educationalSystem}<span className="text-red-500 text-sm"> *</span></label>
                            <Select
                                id='educationalSystem'
                                options={options?.educationalSystems}
                                isSearchable
                                classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                                value={options?.educationalSystems.find(op => op.value == formData.educationalSystem || op.value == formData.educationalSystem?._id) || ""}
                                onChange={op => {
                                    let updatedErrors = { ...errors }
                                    delete updatedErrors['educationalSystem']
                                    setErrors(updatedErrors)

                                    setFormData({ ...formData, educationalSystem: op.value })
                                }}
                                placeholder={translate("Choose your course educational system..")}
                            />
                            {
                                errors.educationalSystem &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.educationalSystem)}
                                </small>
                            }
                        </div>

                        {/* grade level */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="gradeLevel" className="text-start">{required.gradeLevel}<span className="text-red-500 text-sm"> *</span></label>
                            <Select
                                id='gradeLevel'
                                options={options?.gradeLevels}
                                isSearchable
                                classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                                value={options?.gradeLevels.find(op => op.value == formData.gradeLevel || op.value == formData.gradeLevel?._id) || ""}
                                onChange={op => {
                                    let updatedErrors = { ...errors }
                                    delete updatedErrors['gradeLevel']
                                    setErrors(updatedErrors)

                                    setFormData({ ...formData, gradeLevel: op.value })
                                }}
                                placeholder={translate("Choose your course grade level..")}
                            />
                            {
                                errors.gradeLevel &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.gradeLevel)}
                                </small>
                            }
                        </div>
                    </div>
                }

                {
                    formData.type == 'professional' &&
                    <div className="flex gap-x-2 max-md:flex-col">
                        {/* pathway */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="pathway" className="text-start">{required.pathway}<span className="text-red-500 text-sm"> *</span></label>
                            <Select
                                id='pathway'
                                options={options?.pathways}
                                isSearchable
                                classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                                value={options?.pathways.find(op => op.value == formData.pathway || op.value == formData.pathway?._id) || ""}
                                onChange={op => {
                                    let updatedErrors = { ...errors }
                                    delete updatedErrors['pathway']
                                    setErrors(updatedErrors)

                                    setFormData({ ...formData, pathway: op.value })
                                }}
                                placeholder={translate("Choose your course pathway..")}
                            />
                            {
                                errors.pathway &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.pathway)}
                                </small>
                            }
                        </div>

                        {/* order */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="order" className="text-start">{required.order}<span className="text-red-500 text-sm"> *</span></label>
                            <input
                                id="order"
                                type="number"
                                className="input-bar"
                                placeholder={translate("Add order for your course")}
                                value={formData.order || ""}
                                onChange={handleChange}
                            />
                            {
                                errors.order &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.order)}
                                </small>
                            }
                        </div>
                    </div>
                }

                {/* general learning outcomes */}
                <div className="flex flex-col w-full gap-y-2">
                    <label className="text-start font-bold text-lg">{required.learningOutcomes}<span className="text-red-500 text-sm"> *</span></label>
                    {
                        formData.learningOutcomes.map((lo, loIdx) => (
                            <div key={loIdx} className="flex flex-col gap-y-1 rounded border-dashed border border-gray-400 bg-gray-50 p-2">
                                {/* los header */}
                                <div className="flex justify-between items-center">
                                    <label className="font-bold text-lg">{translate("Learning Outcome")} {loIdx + 1}</label>

                                    {/* delete button */}
                                    <button type="button" className="danger-icon-btn" onClick={() => deleteLO(loIdx)}>
                                        <MdDelete size={18} />
                                    </button>
                                </div>

                                {/* statement */}
                                <div className="flex flex-col w-full">
                                    <label htmlFor={`lo-${loIdx}-statement`} className="text-start">{translate(`Statement`)}<span className="text-red-500 text-sm"> *</span></label>
                                    <input value={lo.statement} id={`lo-${loIdx}-statement`} type="text" className="input-bar" placeholder={translate("Add your learning outcome statement..")} onChange={e => { handleLOFieldChange(e, loIdx) }} />
                                    {
                                        errors[`lo-${loIdx}-statement`] &&
                                        <small className='text-red-700 flex items-center gap-1'>
                                            <MdErrorOutline /> {translate(errors[`lo-${loIdx}-statement`])}
                                        </small>
                                    }
                                </div>

                                {/* scope and domain */}
                                <div className="flex gap-x-2 gap-y-1 max-md:flex-col">
                                    {/* scope */}
                                    <div className="flex flex-col w-full">
                                        <label htmlFor={`lo-${loIdx}-scope`} className="text-start">{translate(`Scope`)}<span className="text-red-500 text-sm"> *</span></label>
                                        <input value={lo.scope} id={`lo-${loIdx}-scope`} type="text" className="input-bar" placeholder={translate("Add your learning outcome scope..")} onChange={e => { handleLOFieldChange(e, loIdx) }} />
                                        {
                                            errors[`lo-${loIdx}-scope`] &&
                                            <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`lo-${loIdx}-scope`])}
                                            </small>
                                        }
                                    </div>

                                    {/* domain */}
                                    <div className="flex flex-col w-full">
                                        <label htmlFor={`lo-${loIdx}-domain`} className="text-start">{translate(`Domain`)}<span className="text-red-500 text-sm"> *</span></label>
                                        <input value={lo.domain} id={`lo-${loIdx}-domain`} type="text" className="input-bar" placeholder={translate("Add your learning outcome domain..")} onChange={e => { handleLOFieldChange(e, loIdx) }} />
                                        {
                                            errors[`lo-${loIdx}-domain`] &&
                                            <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`lo-${loIdx}-domain`])}
                                            </small>
                                        }
                                    </div>
                                </div>

                                {/* Performance Criteria */}
                                <div className="flex flex-col gap-y-1">
                                    <div className="text-start font-bold text-lg">{translate(`Performance Criteria`)}<span className="text-red-500 text-sm"> *</span></div>
                                    {
                                        lo.performanceCriteria.map((pc, cIdx) => (
                                            <div key={cIdx} className="flex flex-col w-full p-2 gap-y-1 rounded border border-dashed bg-gray-100 border-gray-400 text-sm">
                                                {/* criteria description */}
                                                <div className="flex gap-x-2 justify-between">
                                                    <label className="font-bold text-base">{translate("Criteria")} {cIdx + 1}</label>
                                                    <button type="button" className="danger-icon-btn" onClick={() => { deleteLOCriteria(loIdx, cIdx) }}>
                                                        <MdDelete size={18} />
                                                    </button>
                                                </div>

                                                <div className="flex flex-col w-full">
                                                    <label htmlFor={`lo-${loIdx}-pc-${cIdx}-description`} className="text-start">{translate("Description")}<span className="text-red-500 text-sm"> *</span></label>
                                                    <input
                                                        id={`lo-${loIdx}-pc-${cIdx}-description`}
                                                        type="text"
                                                        className="input-bar"
                                                        placeholder={translate("Add description")}
                                                        value={pc.description || ""}
                                                        onChange={(e) => { handleLOCriteriaChange(e, loIdx, cIdx) }}
                                                    />
                                                </div>

                                                {
                                                    errors[`lo-${loIdx}-pc-${cIdx}-description`] &&
                                                    <small className='text-red-700 flex items-center gap-1'>
                                                        <MdErrorOutline /> {translate(errors[`lo-${loIdx}-pc-${cIdx}-description`])}
                                                    </small>
                                                }
                                            </div>
                                        ))
                                    }
                                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewLOCriteria(loIdx)}>
                                        <FaPlus /> {translate("Add Performance Criteria")}
                                    </button>
                                    {
                                        errors[`lo-${loIdx}-performanceCriteria`] &&
                                        <small className='text-red-700 flex items-center gap-1'>
                                            <MdErrorOutline /> {translate(errors[`lo-${loIdx}-performanceCriteria`])}
                                        </small>
                                    }
                                </div>
                            </div>
                        ))
                    }

                    {/* new lo button */}
                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => {
                        let updatedErrors = { ...errors }
                        delete updatedErrors.learningOutcomes

                        let updatedFormData = { ...formData }
                        updatedFormData.learningOutcomes.push({ statement: "", scope: "", domain: "", performanceCriteria: [] })
                        setFormData(updatedFormData)

                        const loIdx = updatedFormData.learningOutcomes.length - 1
                        updatedErrors[`lo-${loIdx}-statement`] = "Statement field is required"
                        updatedErrors[`lo-${loIdx}-scope`] = "Scope field is required"
                        updatedErrors[`lo-${loIdx}-domain`] = "Domain field is required"
                        updatedErrors[`lo-${loIdx}-performanceCriteria`] = "You have to add 1 criteria at least!"

                        setErrors(updatedErrors)
                    }}>
                        <FaPlus />
                        {translate("New Learning Outcome")}
                    </button>

                    {
                        errors.learningOutcomes &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.learningOutcomes)}
                        </small>
                    }
                </div>

                {/* modules */}
                <div className="flex flex-col w-full gap-y-2">
                    <label className="text-start font-bold text-lg">{required.modules}<span className="text-red-500 text-sm"> *</span></label>
                    {
                        formData.modules.map((module, moduleIdx) => (
                            <div key={moduleIdx} className="flex flex-col gap-y-1 rounded border-dashed border border-gray-400 bg-gray-50 p-2">
                                {/* module header */}
                                <div className="flex justify-between items-center">
                                    <label className="font-bold text-lg">{translate("Module")} {moduleIdx + 1}</label>
                                    <button type="button" className="danger-icon-btn" onClick={() => deleteModule(moduleIdx)}>
                                        <MdDelete size={18} />
                                    </button>
                                </div>

                                {/* module number & name */}
                                <div className="flex gap-x-2 max-md:flex-col">
                                    {/* module number */}
                                    <div className="flex flex-col w-full">
                                        <label htmlFor={`module-${moduleIdx}-number`} className="text-start">{translate("Number")}<span className="text-red-500 text-sm"> *</span></label>
                                        <input
                                            id={`module-${moduleIdx}-number`}
                                            type="number"
                                            className="input-bar"
                                            placeholder={translate("Add module number")}
                                            value={module.number || ""}
                                            onChange={(e) => handleModuleChange(e, moduleIdx)}
                                        />
                                        {
                                            errors[`module-${moduleIdx}-number`] &&
                                            <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-number`])}
                                            </small>
                                        }
                                    </div>

                                    {/* module name */}
                                    <div className="flex flex-col w-full">
                                        <label htmlFor={`module-${moduleIdx}-name`} className="text-start">{translate("Name")}<span className="text-red-500 text-sm"> *</span></label>
                                        <input
                                            id={`module-${moduleIdx}-name`}
                                            type="text"
                                            className="input-bar"
                                            placeholder={translate("Add module name")}
                                            value={module.name || ""}
                                            onChange={(e) => handleModuleChange(e, moduleIdx)}
                                        />
                                        {
                                            errors[`module-${moduleIdx}-name`] &&
                                            <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-name`])}
                                            </small>
                                        }
                                    </div>
                                </div>

                                {/* lessons */}
                                <div className="flex flex-col gap-y-1">
                                    <label className="text-start font-bold text-lg">{translate("Lessons")}<span className="text-red-500 text-sm"> *</span></label>
                                    {
                                        module.lessons.map((lesson, lessonIdx) => (
                                            <div key={lessonIdx} className="flex flex-col w-full p-2 rounded border border-dashed bg-gray-100 border-gray-400 gap-y-1 text-sm">
                                                {/* lesson header */}
                                                <div className="flex justify-between items-center">
                                                    <label className="text-md font-bold text-base">{translate("Lesson")} {lessonIdx + 1}</label>
                                                    <button type="button" className="danger-icon-btn" onClick={() => deleteLesson(moduleIdx, lessonIdx)}>
                                                        <MdDelete size={18} />
                                                    </button>
                                                </div>

                                                {/* lesson number & name */}
                                                <div className="flex gap-x-2 max-md:flex-col">
                                                    {/* lesson number */}
                                                    <div className="flex flex-col w-full">
                                                        <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-number`} className="text-start">{translate("Number")}<span className="text-red-500 text-sm"> *</span></label>
                                                        <input
                                                            id={`module-${moduleIdx}-lesson-${lessonIdx}-number`}
                                                            type="number"
                                                            className="input-bar"
                                                            placeholder={translate("Add lesson number")}
                                                            value={lesson.number || ""}
                                                            onChange={(e) => handleLessonChange(e, moduleIdx, lessonIdx)}
                                                        />
                                                        {
                                                            errors[`module-${moduleIdx}-lesson-${lessonIdx}-number`] &&
                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-number`])}
                                                            </small>
                                                        }
                                                    </div>

                                                    {/* lesson name */}
                                                    <div className="flex flex-col w-full">
                                                        <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-name`} className="text-start">{translate("Name")}<span className="text-red-500 text-sm"> *</span></label>
                                                        <input
                                                            id={`module-${moduleIdx}-lesson-${lessonIdx}-name`}
                                                            type="text"
                                                            className="input-bar"
                                                            placeholder={translate("Add lesson name")}
                                                            value={lesson.name || ""}
                                                            onChange={(e) => handleLessonChange(e, moduleIdx, lessonIdx)}
                                                        />
                                                        {
                                                            errors[`module-${moduleIdx}-lesson-${lessonIdx}-name`] &&
                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-name`])}
                                                            </small>
                                                        }
                                                    </div>
                                                </div>

                                                {/* lesson learning outcomes */}
                                                <div className="flex flex-col gap-y-1">
                                                    <label className="text-start text-base font-bold">{translate("Learning Outcomes")}<span className="text-red-500 text-sm"> *</span></label>
                                                    {
                                                        lesson.learningOutcomes.map((outcome, outcomeIdx) => (
                                                            <div key={outcomeIdx} className="flex flex-col w-full p-2 rounded border border-dashed bg-gray-50 border-gray-400 gap-y-1 text-sm">
                                                                <div key={outcomeIdx} className="flex gap-x-2 justify-between">
                                                                    <label className="text-start font-bold">{translate("Learning Outcome")} {outcomeIdx + 1}</label>
                                                                    <button type="button" className="text-red-500" onClick={() => deleteLessonLO(moduleIdx, lessonIdx, outcomeIdx)}>
                                                                        <MdDelete size={18} />
                                                                    </button>
                                                                </div>

                                                                <div className="flex flex-col w-full">
                                                                    <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-statement`} className="text-start">{translate("Statement")}<span className="text-red-500 text-sm"> *</span></label>
                                                                    <input
                                                                        id={`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-statement`}
                                                                        type="text"
                                                                        className="input-bar"
                                                                        placeholder={translate("Add learning outcome")}
                                                                        value={outcome || ""}
                                                                        onChange={(e) => handleLessonLOFieldChange(e, moduleIdx, lessonIdx, outcomeIdx)}
                                                                    />

                                                                    {
                                                                        errors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-statement`] &&
                                                                        <small className='text-red-700 flex items-center gap-1'>
                                                                            <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}-statement`])}
                                                                        </small>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewLessonLO(moduleIdx, lessonIdx)}>
                                                        <FaPlus /> {translate("Add Learning Outcome")}
                                                    </button>
                                                    {
                                                        errors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] &&
                                                        <small className='text-red-700 flex items-center gap-1'>
                                                            <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`])}
                                                        </small>
                                                    }
                                                </div>

                                                {/* lesson content */}
                                                <div className="flex flex-col w-full">
                                                    <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-content`} className="text-start">{translate("Content")}<span className="text-red-500 text-sm"> *</span></label>
                                                    <textarea
                                                        id={`module-${moduleIdx}-lesson-${lessonIdx}-content`}
                                                        className="input-bar min-h-[100px] small-scroll"
                                                        placeholder={translate("Add lesson content")}
                                                        value={lesson.content || ""}
                                                        onChange={(e) => handleLessonChange(e, moduleIdx, lessonIdx)}
                                                    />
                                                    {
                                                        errors[`module-${moduleIdx}-lesson-${lessonIdx}-content`] &&
                                                        <small className='text-red-700 flex items-center gap-1'>
                                                            <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-content`])}
                                                        </small>
                                                    }
                                                </div>

                                                {/* lesson sample questions */}
                                                <div className="flex flex-col gap-y-1">
                                                    <label className="text-start text-base font-bold">{translate("Sample Questions")}<span className="text-red-500 text-sm"> *</span></label>
                                                    {
                                                        lesson.sampleQuestions.map(({ type, questions }, questionsIdx) => (
                                                            <div key={questionsIdx} className="flex flex-col w-full p-2 rounded border border-dashed bg-gray-50 border-gray-400 gap-y-1 text-sm">
                                                                {/* sample questions header */}
                                                                <div className="flex justify-between items-center">
                                                                    <label className="text-md font-bold">{translate("Sample Questions")} {questionsIdx + 1}</label>
                                                                    <button type="button" className="text-red-500" onClick={() => deleteSampleQuestions(moduleIdx, lessonIdx, questionsIdx)}>
                                                                        <MdDelete size={18} />
                                                                    </button>
                                                                </div>

                                                                {/* sample questions type & questions */}
                                                                <div className="flex flex-col gap-y-1">
                                                                    {/* questions type */}
                                                                    <div className="flex flex-col w-full">
                                                                        <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`} className="text-start">{translate("Type")}<span className="text-red-500 text-sm"> *</span></label>
                                                                        <input
                                                                            id={`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`}
                                                                            type="text"
                                                                            className="input-bar"
                                                                            placeholder={translate("Add question type")}
                                                                            value={type || ""}
                                                                            onChange={(e) => handleSampleQuestionsChange(e, moduleIdx, lessonIdx, questionsIdx)}
                                                                        />
                                                                        {
                                                                            errors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`] &&
                                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                                <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`])}
                                                                            </small>
                                                                        }
                                                                    </div>

                                                                    {/* actual questions */}
                                                                    <div className="flex flex-col w-full">
                                                                        <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`} className="text-start">{translate("Questions")}<span className="text-red-500 text-sm"> *</span></label>
                                                                        <textarea
                                                                            id={`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`}
                                                                            className="input-bar min-h-[100px] small-scroll"
                                                                            placeholder={translate("Add questions")}
                                                                            value={questions || ""}
                                                                            onChange={(e) => handleSampleQuestionsChange(e, moduleIdx, lessonIdx, questionsIdx)}
                                                                        />
                                                                        {
                                                                            errors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`] &&
                                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                                <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`])}
                                                                            </small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewSampleQuestions(moduleIdx, lessonIdx)}>
                                                        <FaPlus /> {translate("Add Sample Questions")}
                                                    </button>
                                                    {
                                                        errors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] &&
                                                        <small className='text-red-700 flex items-center gap-1'>
                                                            <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`])}
                                                        </small>
                                                    }
                                                </div>

                                                {/* lesson other info */}
                                                <div className="flex flex-col w-full">
                                                    <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`} className="text-start">{translate("Other Info")}</label>
                                                    <textarea
                                                        id={`module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`}
                                                        className="input-bar min-h-[100px] small-scroll"
                                                        placeholder={translate("Add other info")}
                                                        value={lesson.otherInfo || ""}
                                                        onChange={(e) => handleLessonChange(e, moduleIdx, lessonIdx)}
                                                    />
                                                    {
                                                        errors[`module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`] &&
                                                        <small className='text-red-700 flex items-center gap-1'>
                                                            <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`])}
                                                        </small>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewLesson(moduleIdx)}>
                                        <FaPlus /> {translate("Add Lesson")}
                                    </button>
                                    {
                                        errors[`module-${moduleIdx}-lessons`] &&
                                        <small className='text-red-700 flex items-center gap-1'>
                                            <MdErrorOutline /> {translate(errors[`module-${moduleIdx}-lessons`])}
                                        </small>
                                    }
                                </div>
                            </div>
                        ))
                    }
                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={addNewModule}>
                        <FaPlus /> {translate("Add Module")}
                    </button>
                    {
                        errors.modules &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.modules)}
                        </small>
                    }
                </div>

                {/* create button */}
                <button className="submit-btn w-full mt-4" disabled={loading || Object.values(errors).some(v => v)}>
                    {
                        loading ?
                            <span className='flex justify-center text-gray-50 text-[24px]'>
                                <AiOutlineLoading3Quarters className='animate-spin' />
                            </span>
                            : <span className='capitalize'>{translate(initial ? 'Save Updates' : 'Create Course')}</span>
                    }
                </button>
            </form>
        </div>
    )
}


export default CourseForm