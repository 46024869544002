"use client"

import { createSlice } from "@reduxjs/toolkit"
import { io } from "socket.io-client"
import { SOCKET_ROOT } from "@constants"
import { dev_console_log } from "@utils";


const socketSlice = createSlice({
    name: 'socket',
    initialState: { connection: null },
    reducers: {
        openSocket: (self, action) => {
            if (!self.connection) {
                const { user } = action.payload
                self.connection = io(SOCKET_ROOT, { auth: { userId: user.id } })

                // check if connected successfully
                self.connection.on('connect', () => {
                    dev_console_log('Socket Connected!')
                })
            } else {
                if (self.connection.connected) {
                    dev_console_log('Socket is already Connected!')
                } else {
                    dev_console_log('Socket is Connecting..')
                }
            }
        },
        socketListen: (self, action) => {
            const { event, callback } = action.payload

            // Ensure socket connection exists before listening
            if (self.connection) {
                self.connection.off(event);  // Prevent duplicate listeners
                self.connection.on(event, callback)
                // dev_console_log(`'${event}' event listener is added to socket`)  
            } else {
                console.info("Socket connection not initialized. Unable to listen to events.")
            }
        },
        socketEmit: (self, action) => {
            const { event, data, callback } = action.payload;
            
            // Ensure that the connection exists before emitting
            if (self.connection) {
                self.connection.emit(event, data, callback);
                dev_console_log(`'${event}' event emitted`)
            } else {
                console.info("Socket connection not initialized. Unable to emit events.");
            }
        },
        closeSocket: (self) => {
            // Disconnect if a connection exists
            if (self.connection) {
                self.connection.disconnect();
                self.connection = null;

                // dev_console_log('Socket Close!')
            }
        },
    },
})


// actions
export const { openSocket, socketListen, socketEmit, closeSocket } = socketSlice.actions

// selectors
export const isSocketConnected = (state) => Boolean(state.socket.connection?.connected)

// Export the reducer, either as a default or named export
export default socketSlice.reducer 