"use client";

import { Transition } from '@headlessui/react';

const FlyInOutTransition = ({ show, direction, children, ...props }) => {
    if (direction) {
        const enterFrom = direction == 'rtl' ? '-translate-x-full' : 'translate-x-full';
        const leaveTo = direction == 'rtl' ? 'translate-x-full' : '-translate-x-full';

        return (
            <Transition
                {...props}
                show={show}
                enter="transform transition ease-linear duration-700"
                enterFrom={enterFrom}
                enterTo="translate-x-0"
                leave="transform transition ease-linear duration-700"
                leaveFrom="translate-x-0"
                leaveTo={leaveTo}
            >

                {children}
            </Transition>
        )
    }


    return (
        <Transition
            {...props}
            show={show}
            enter="transition-opacity ease-linear duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>

    );
};

export default FlyInOutTransition;