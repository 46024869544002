"use client"

import { BACKEND_ROOT } from "@constants";
import { AxiosRequest , dev_console_log } from "@utils";
import { createSlice } from '@reduxjs/toolkit';

const announcementsSlice = createSlice({
    name: 'announcements',
    initialState: {
        announcements10: [],
        announcementsAll: [],
        antdPageSize: 10,
        antdTotal: 0,
        total_acc: 0,
    },
    reducers: {
        setAntdPageSize: (state, action) => {
            dev_console_log("antdPageSize changes - start");
            state.antdPageSize = action.payload;
            dev_console_log("antdPageSize changes - done");
        },
        setAntdTotal: (state, action) => {
            dev_console_log("antdTotal changes - start");
            state.antdTotal = action.payload;
            dev_console_log("antdTotal changes - done");
        },
        setAnnouncements10: (state, action) => {
            // dev_console_log("state")
            // dev_console_log(state)
            // dev_console_log("action")
            // dev_console_log(action)
            state.announcements10 = action.payload;
        },
        setAnnouncementsAll: (state, action) => {
            // dev_console_log("state")
            // dev_console_log(state)
            // dev_console_log("action")
            // dev_console_log(action)
            state.announcementsAll = action.payload;
        },
        addSingleAnnouncement: (state, action) => {
            const newAnnouncement = action.payload;

            state.announcements10 = [newAnnouncement, ...state.announcements10];

            const tmp = [newAnnouncement, ...state.announcementsAll];
            state.total_acc = 1 + state.antdTotal;
            state.announcementsAll = tmp.slice(0, state.antdPageSize);
        },
        deleteSingleAnnouncement: (state, action) => {
            dev_console_log('in deletedAnnouncement');
            const tmp_id = action.payload;
            dev_console_log("tmp_id");
            dev_console_log(tmp_id);
            const indexAnnouncements10 = state.announcements10.findIndex(obj => obj._id === tmp_id);
            dev_console_log("indexAnnouncements10");
            dev_console_log(indexAnnouncements10);

            if (indexAnnouncements10 !== -1) {
                dev_console_log("state.announcements10[indexAnnouncements10]");
                dev_console_log(state.announcements10[indexAnnouncements10]);
                state.announcements10.splice(indexAnnouncements10, 1);
            }

            const indexAnnouncementsAll = state.announcementsAll.findIndex(obj => obj._id === tmp_id);
            dev_console_log("indexAnnouncementsAll");
            dev_console_log(indexAnnouncementsAll);

            if (indexAnnouncementsAll !== -1) {
                dev_console_log("state.announcementsAll[indexAnnouncementsAll]");
                dev_console_log(state.announcementsAll[indexAnnouncementsAll]);
                state.announcementsAll.splice(indexAnnouncements10, 1);
            }
        },
        // emptyAnnouncementsAll: (state) => {
        //     state.announcementsAll = [];
        // },
        readSingleAnnouncement: (state, action) => {
            const tmp_id = action.payload;
            dev_console_log("tmp_id");
            dev_console_log(tmp_id);
            const indexAnnouncements10 = state.announcements10.findIndex(obj => obj._id === tmp_id);
            dev_console_log("indexAnnouncements10");
            dev_console_log(indexAnnouncements10);

            if (indexAnnouncements10 !== -1) {
                const old_item = state.announcements10[indexAnnouncements10];
                const new_item = { ...old_item };
                new_item.is_read = true;
                state.announcements10[indexAnnouncements10] = new_item;
                dev_console_log("state.announcements10[indexAnnouncements10]");
                dev_console_log(state.announcements10[indexAnnouncements10]);
            }

            const indexAnnouncementsAll = state.announcementsAll.findIndex(obj => obj._id === tmp_id);
            dev_console_log("indexAnnouncementsAll");
            dev_console_log(indexAnnouncementsAll);

            if (indexAnnouncementsAll !== -1) {
                const old_item = state.announcementsAll[indexAnnouncementsAll];
                const new_item = { ...old_item };
                new_item.is_read = true;
                state.announcementsAll[indexAnnouncementsAll] = new_item;
                dev_console_log("state.announcementsAll[indexAnnouncementsAll]");
                dev_console_log(state.announcementsAll[indexAnnouncementsAll]);
            }

            AxiosRequest?.put(`${BACKEND_ROOT}/announcements/${tmp_id}`)
        },
    },
})

// Extract and export each action creator by name
export const { setAntdTotal, setAntdPageSize, setAnnouncementsAll, setAnnouncements10, addSingleAnnouncement, readSingleAnnouncement, deleteSingleAnnouncement } = announcementsSlice.actions

// selectors
export const getAnnouncements10 = (state) => state.announcements?.announcements10;
export const getUnreadAnnouncementsCount = (state) => state.announcements?.announcements10?.filter((x) => !((x?.is_read ?? 0) - 0))?.length ?? 0;

export const getAnnouncementsAll = (state) => state.announcements?.announcementsAll;
export const getTotal_acc = (state) => state.announcements?.total_acc;
// export const getUnreadAnnouncementsAllCount = (state) => state.announcements?.announcementsAll?.filter((x) => !((x?.is_read ?? 0) - 0))?.length ?? 0;

export default announcementsSlice.reducer;