'use client';

import StoreProvider from "./StoreProvider"

const withStore = (Component) => {
    const WrappedComponent = (props) => (
        <StoreProvider>
            <Component {...props} />
        </StoreProvider>
    )

    WrappedComponent.displayName = `withStore(${Component.displayName || Component.name || 'Component'})`

    return WrappedComponent
}

export default withStore;