"use client";

import { persistor, store } from "@lib/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { getDict, setDict } from "@lib/reducers/dictReducer";
import { getUser, setUser } from "@lib/reducers/userReducer";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Ensure this is correctly imported without curly braces.
import { AxiosRequest, dev_console_log } from "@utils";
import { BACKEND_ROOT } from "@constants";


const StoreProvider = ({ children }) => {

  const handleBeforeLift = async () => {
    const state = store.getState();

    // Only set dict and user in the state if they're not already initialized.
    if (!getDict(state)) {
      // get dict
      const dict = await AxiosRequest.get(`${BACKEND_ROOT}/getCachedDict`).then(res => res.data).catch(err => {
        dev_console_log(`Failed to import dict: ${err.message}`)

        return {}
      })

      store.dispatch(setDict(dict));
    }
    
    if (!getUser(state)) {
      // extract user
      const accessToken = Cookies.get('access_token');
      const user = accessToken ? jwtDecode(accessToken).user : null;

      if (user) store.dispatch(setUser(user));
    }
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null} onBeforeLift={handleBeforeLift}>
        {children}
      </PersistGate>
    </Provider>
  )
}

export default StoreProvider
