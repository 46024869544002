"use client"

import { createSlice } from "@reduxjs/toolkit"

const langSlice = createSlice({
    name: 'lang',
    initialState: { value: "en" },
    reducers: {
        setLang: (state, action) => {
            state.value = action.payload;
            document.documentElement.setAttribute('lang', state.value);
            document.documentElement.setAttribute('dir', state.value == 'ar' ? 'rtl' : 'ltr');
        },
    },
})

// actions
export const { setLang } = langSlice.actions

// selectors
export const getLang = (state) => state.lang.value

// Export the reducer, either as a default or named export
export default langSlice.reducer 