"use client"

import { BACKEND_ROOT, FAWATERAK_PROVIDER_KEY, FAWATERAK_VENDOR_KEY } from '@constants'
import { getUser } from '@lib/reducers/userReducer'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createHmac } from 'crypto'
import Script from 'next/script'
import { AxiosRequest, decrypt, dev_console_log } from "@utils"
import Loading from './Loading'
import { redirect } from 'next/navigation'

const FawaterakPayment = ({ cartItems, cartTotal }) => {
  // invoice way
  const [iframeUrl, setIframeUrl] = useState(null)

  useEffect(() => {
    AxiosRequest.post(`${BACKEND_ROOT}/fawaterak/getOrCreateInvoice`, {
      cartItems, cartTotal, backTo: window.location.href
    }).then(res => {
      setIframeUrl(res.data.url);
    }).catch(err => {
      dev_console_log(err.message || err.code);
    })
  }, [cartItems, cartTotal])

  if (!iframeUrl) return <Loading />

  // return redirect(iframeUrl)
  return <iframe src={iframeUrl} className='flex-grow' />

  // ==================================================================================================
  // ==================================================================================================
  // // backend rendering way
  // const [iframeView, setIframeView] = useState(null)

  // useEffect(() => {
  //   AxiosRequest.get(`${BACKEND_ROOT}/fawaterak/iframe?amount=${amount}`)
  //     .then(res => {
  //       const encrypted = res.data
  //       setIframeView(decrypt(encrypted));
  //     }).catch(err => {
  //       dev_console_log(err.message || err.code);
  //     })
  // }, [amount])

  // if (!iframeView) return <Loading />
  
  // return <iframe srcDoc={iframeView} className='flex-grow' />

  // ==================================================================================================
  // ==================================================================================================
  // // iframe way
  // const user = useSelector(getUser)

  // const generateHashKey = () => {
  //   const secretKey = FAWATERAK_VENDOR_KEY
  //   const queryParam = `Domain=${'lwe.ai'}&ProviderKey=${FAWATERAK_PROVIDER_KEY}`
  //   const hash = createHmac('sha256', secretKey).update(queryParam).digest('hex')
  //   // dev_console_log(window.location.hostname);

  //   return hash
  // }

  // const onScriptLoad = () => {
  //   const callbackUrl = `${BACKEND_ROOT}/fawaterak/callback?backTo=${window.location.href}`

  //   var pluginConfig = {
  //     envType: "test",
  //     hashKey: generateHashKey(),
  //     style:{
  //       listing:"horizontal"
  //     },
  //     requestBody: {
  //         "cartTotal": cartTotal,
  //         "currency": "EGP",
  //         "customer": {
  //             "first_name": user.firstName,
  //             "last_name": user.lastName,
  //             "email": user.email,
  //             "phone": user.phone,
  //         },
  //         "redirectionUrls": {
  //             "successUrl": callbackUrl,
  //             "failUrl": callbackUrl,
  //             "pendingUrl": callbackUrl
  //         },
  //         "cartItems": cartItems,
  //     }
  // };

  //   window.pluginConfig = pluginConfig
  //   window.fawaterkCheckout(pluginConfig)
  // }
  
  // return (
  //   <Fragment>
  //     <Script
  //       src="https://staging.fawaterk.com/fawaterkPlugin/fawaterkPlugin.min.js"
  //       strategy="lazyOnload"
  //       onLoad={onScriptLoad}
  //     />
  //     <div id="fawaterkDivId" />
  //   </Fragment>
  // )
}

export default FawaterakPayment