"use client"

import { getTimeAgo } from "@utils"
import Link from "next/link"
import PostCardOptions from "./PostCardOptions"
import LikesAndComments from "./LikesAndComments"
import BadgeSingleComp from "@client-components/BadgeSingleComp";


const PostCard = ({
    p,
    user,
    lang
}) => {
    return (
        <div id={`post-${p._id}`} className="bg-gray-100 border border-gray-300 shadow p-4 flex flex-col gap-y-4 rounded-lg">
            {/* post details */}
            <div className="flex gap-x-2 justify-between items-start">
                <div className="flex flex-col gap-y-2 w-full">
                    {/* publisher */}
                    <div className="flex gap-x-2 justify-between items-center text-gray-500">
                        <div>
                            <h1 className="font-semibold text-xl">{p.user.firstName} {p.user.lastName}</h1>
                            {
                                (() => {
                                    const tmp_BadgesArr = p?.user?.BadgesArr;
                                    if (!(tmp_BadgesArr?.length)) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            style={{
                                                overflowY: "auto",
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "center",
                                            }}
                                        >
                                            {
                                                tmp_BadgesArr.map((badge,index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: "50px",
                                                            }}
                                                        >
                                                            <BadgeSingleComp
                                                                parent_is={"parent_is"}
                                                                badge={badge}
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    )
                                })()
                            }
                        </div>
                        <span className="italic text-sm">{getTimeAgo(p.createdAt)}</span>
                    </div>

                    {/* post content */}
                    <div className="flex flex-col gap-y-1">
                        <Link className="flex flex-col gap-y-1 w-full" href={`/${lang}/course/${p.course.seoTxt}/forum/${p.seoTxt}`}>
                            <div id={`post-${p._id}-content`} dangerouslySetInnerHTML={{ __html: p.content }} />
                        </Link>
                    </div>
                </div>

                {/* post options */}
                {user && <PostCardOptions p={p} />}
            </div>

            {/* divider */}
            <div className="h-[1px] bg-gray-300 w-full" />

            {/* likes & comments */}
            <LikesAndComments p={p} />
        </div>
    )
}


export default PostCard