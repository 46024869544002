"use client"

import { CustomModal, LearningTimesForm } from "@client-components"
import { useState } from "react"
import { useParams, useRouter } from "next/navigation"
import { AxiosRequest, dev_console_log } from "@utils"
import { BACKEND_ROOT } from "@constants"
import { useTranslate } from "@hooks"
import Link from "next/link"
import { withStore } from "@store-components"


const EnrollCourseButton = ({ user, courseId, isEnrolled }) => {
    const { lang } = useParams()
    const router = useRouter()
    const translate = useTranslate()

    const [modal, setModal] = useState({ open: false })
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    const handleEnroll = async (body) => {
        await AxiosRequest.post(`${BACKEND_ROOT}/course/createCourseProgress/${courseId}`, body)
            .then(res => {
                closeModal()
                router.push(`/${lang}/dashboard/course-progress/${res.data._id}`)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }

    return (
        <div className="flex gap-x-2 items-center">
            <button className="submit-btn text-sm w-fit" onClick={() => {
                if (!user) return router.push(`/${lang}/login`);

                if (isEnrolled) return router.push(`/${lang}/dashboard/course-progress/${courseId}`);

                openModal()
            }}>
                {translate(isEnrolled ? "Go To Course" : user?.permissionLevel > 3 ? `Enroll (As Student)` : `Enroll Now`)}
            </button>

            {user?.permissionLevel > 3 && <Link href={`/${lang}/dashboard/course-progress/${courseId}`} className='submit-btn-outline w-fit text-sm'>{translate("Enroll (As Teacher)")}</Link>}

            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-[1080px] min-w-[720px]`}>
                <LearningTimesForm handleFormAction={handleEnroll} />
            </CustomModal>
        </div>
    )
}

export default withStore(EnrollCourseButton)