"use client"

import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    PAYMOB_API_KEY,
    PAYMOB_INTEGRATION_ID,
    PAYMOB_IFRAME_ID,
    PAYMOB_BASE_URL,
    BACKEND_ROOT,
} from '@constants';
import { useSelector } from 'react-redux';
import { getUser } from '@lib/reducers/userReducer';
import Loading from './Loading';

const PaymobPayment = ({ amount, ...props }) => {
    const user = useSelector(getUser);
    const userDetails = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone_number: user.phone,
    };


    const [iframeUrl, setIframeUrl] = useState('');


    useEffect(() => {
        const initiatePayment = async () => {
            try {
                // Step 1: Authentication
                const authResponse = await axios.post(`${PAYMOB_BASE_URL}/auth/tokens`, {
                    api_key: PAYMOB_API_KEY
                });
                const authToken = authResponse.data.token;

                // Step 2: Order Registration
                const orderData = {
                    auth_token: authToken,
                    delivery_needed: 'false',
                    amount_cents: amount * 100,     // Amount in cents
                    currency: 'EGP',                // Change to your currency
                    items: [],
                };

                const orderResponse = await axios.post(`${PAYMOB_BASE_URL}/ecommerce/orders`, orderData);
                const orderId = orderResponse.data.id;
                
                // Step 3: Payment Key Request
                const paymentData = {
                    auth_token: authToken,
                    amount_cents: amount * 100,
                    expiration: 3600,
                    order_id: orderId,
                    billing_data: {
                        ...userDetails,
                        apartment: "NA",
                        floor: "NA",
                        street: "NA",
                        building: "NA",
                        city: "NA",
                        country: "NA",
                    },
                    currency: 'EGP',
                    integration_id: PAYMOB_INTEGRATION_ID,
                    redirect_url: `${BACKEND_ROOT}/paymob/response-callback?backTo=${window.location.href}`
                };

                const paymentKeyResponse = await axios.post(`${PAYMOB_BASE_URL}/acceptance/payment_keys`, paymentData);
                const paymentKey = paymentKeyResponse.data.token;

                // Step 4: Generate Iframe URL
                const iframeUrl = `${PAYMOB_BASE_URL}/acceptance/iframes/${PAYMOB_IFRAME_ID}?payment_token=${paymentKey}`;
                setIframeUrl(iframeUrl);
            } catch (error) {
                console.info('Error initiating payment:', error);
            }
        };

        if (amount > 0) initiatePayment()
    }, [amount]);


    if (!iframeUrl) return <Loading />
    return (
        <iframe
            src={iframeUrl}
            className='flex flex-col flex-grow'
            title="Paymob Payment"
            {...props}
        />
    )
};

export default PaymobPayment;