"use client"

import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: 'user',
    initialState: { details: null, statistics: { expValue: 0, level: 0, coins: 0, streakDays: 0, freezeChances: 0, BadgesArr: [] } },
    reducers: {
        setUser: (self, action) => {
            self.details = action.payload;
        },
        clearUser: (self) => {
            self.details = null;
        },
        setUserStatistics: (self, action) => {
            const data = action.payload;

            Object.keys(self.statistics).forEach(statKey => {
                if (data[statKey]) {
                    self.statistics[statKey] = data[statKey];
                }
            })
        },
        resetUserStatistics: (self) => {
            self.statistics =  { expValue: 0, level: 0, coins: 0, streakDays: 0, freezeChances: 0, BadgesArr: [] };
        },
    },
})

// Extract and export each action creator by name
export const { setUser, clearUser, setUserStatistics, resetUserStatistics } = userSlice.actions

// selectors
export const getUser = (state) => state.user.details;
export const getUserStatistics = (state) => state.user.statistics;

// Export the reducer, either as a default or named export
export default userSlice.reducer