import axios from "axios";
import * as yup from "yup";

// Define the validation schema using Yup
export const jsonCourseSchema = yup.object().shape({
    name: yup.string().required(),
    grade_level: yup.string().required(),
    learning_outcomes: yup.array(
        yup.object().shape({        // array of objects
            statement: yup.string().required(),
            scope: yup.string().required(),
            domain: yup.string().required(),
            performanceCriteria: yup.array(
                yup.string().required()     // array of strings
            ).required(),
        }).required()
    ).required(),
    modules: yup.array(
        yup.object().shape({        // array of objects
            module_number: yup.number().required(),
            module_name: yup.string().required(),
            lessons: yup.array(
                yup.object().shape({        // array of objects
                    lesson_number: yup.number().required(),
                    lesson_name: yup.string().required(),
                    learning_outcomes: yup.array(
                        yup.string().required()     // array of strings
                    ).required(),
                    content: yup.string().required(),       // md content
                    sample_questions: yup.array(            // nested array
                        yup.object().shape({
                            type: yup.string().required(),
                            questions: yup.string().required()  // md questions
                        })
                    ).required(),
                    other_lesson_info: yup.string().optional(),     // nd other info
                })
            ).required()
        })
    ).required()
});

const loadJsonCourse = async (jsonFilePath) => {
    try {
        const jsonData = await axios.get(jsonFilePath).then(res => res.data).catch(err => console.error(err.message))
        await jsonCourseSchema.validate(jsonData, { abortEarly: false });

        return { valid: true, data: jsonData, errors: null }
    } catch (error) {
        if (error instanceof yup.ValidationError) {
            // Handle Yup validation errors
            const errors = [];
            error.inner.forEach(err => {
                errors.push(err.message)
            });

            return { valid: false, data: null, errors }
        }

        // Handle unexpected errors
        return { valid: false, data: null, errors: { [error.name]: `${error.message} in ${error.stack}` } }
    }
}

export default loadJsonCourse