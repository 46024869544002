"use client"

import { BACKEND_ROOT } from "@constants"
import { Fragment, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import { AxiosRequest, formatNumber, dev_console_log } from "@utils";
import { useTranslate } from "@hooks";
import { withStore } from "@store-components"
import { IoPerson } from "@react-icons/all-files/io5/IoPerson";
import { FaMedal } from "@react-icons/all-files/fa/FaMedal";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { GiSportMedal } from "@react-icons/all-files/gi/GiSportMedal";
import { FaChild } from "@react-icons/all-files/fa/FaChild";
import { useSelector } from "react-redux";
import { getUser } from "@lib/reducers/userReducer";
import { getLang } from "@lib/reducers/langReducer";
import Link from "next/link";


const YearRanking = () => {
    const user = useSelector(getUser)
    const lang = useSelector(getLang)
    const translate = useTranslate();
    const [ranking, setRanking] = useState(null)
    const [lateRankExp, setLateRankExp] = useState(null)
    const currentYear = new Date().getFullYear();


    useEffect(() => {
        AxiosRequest.get(`${BACKEND_ROOT}/exp/getYearRanking`)
            .then(res => {
                const fetchedRanking = res.data
                const lateRankExpIndex = fetchedRanking.sortedYearExps.findIndex(yxp => yxp.rank == 0 || yxp.rank > 5)

                if (lateRankExpIndex > -1) {
                    setLateRankExp(fetchedRanking.sortedYearExps.splice(lateRankExpIndex, 1)[0])
                }

                setRanking(fetchedRanking)
            }).catch(err => {
                dev_console_log(err.message || err.code)
            })
    }, [])


    const rankCards = [
        ({ userExp }) => { // gold (index 0)
            const rankReward = ranking.currentYearRankRewards.find(rr => rr.rank == userExp.rank)

            return (
                <div className="flex gap-x-2 p-2 items-center justify-between bg-amber-100 rounded">
                    <div className="flex gap-x-2 items-center w-full truncate">
                        {userExp.lastStatusUp ? <FaChevronUp className="text-green-600 min-w-[18px] h-fit" /> : <FaChevronDown className="text-red-600 min-w-[18px] h-fit" />}
                        <span>{userExp.rank}</span>
                        <div className="rounded-full h-[50px] min-w-[50px] border-2 border-amber-400">
                            {userExp.user.image ? <img src={userExp.user.image} className="h-full w-full object-cover rounded-full" /> : <IoPerson className="h-full w-full p-1" />}
                        </div>
                        <h1 className="truncate">{`${userExp.user.firstName} ${userExp.user.lastName}`}</h1>
                        {user.id == userExp.user._id && <FaChild className="text-blue-400 h-[30px] w-[30px]" />}
                    </div>

                    <span className="uppercase min-w-fit">{`${formatNumber(userExp.xp)} ${translate("xp")}`}</span>

                    <div className="flex gap-x-2 items-center w-full justify-end">
                        {
                            rankReward?.name &&
                            <h1 className="capitalize truncate">
                                {
                                    rankReward.description ?
                                        <Link className="hover-link" href={`/${lang}/dashboard/ranking-reward/${rankReward._id}`}>{rankReward.name}</Link>
                                        : <span className="truncate">{rankReward.name}</span>
                                }
                            </h1>
                        }

                        {
                            rankReward?.image ?
                                <img src={rankReward.image} className="h-[50px] min-w-[50px] border-2 border-amber-400 object-cover rounded-full" />
                                : <FaMedal className="h-[30px] w-[30px] text-amber-400" />
                        }
                    </div>
                </div>
            )
        },
        ({ userExp }) => {  // silver (index 1)
            const rankReward = ranking.currentYearRankRewards.find(rr => rr.rank == userExp.rank)

            return (
                <div className="flex gap-x-2 p-2 items-center justify-between bg-slate-100 rounded">
                    <div className="flex gap-x-2 items-center w-full truncate">
                        {userExp.lastStatusUp ? <FaChevronUp className="text-green-600 min-w-[18px] h-fit" /> : <FaChevronDown className="text-red-600 min-w-[18px] h-fit" />}
                        <span>{userExp.rank}</span>
                        <div className="rounded-full h-[50px] min-w-[50px] border-2 border-slate-400">
                            {userExp.user.image ? <img src={userExp.user.image} className="h-full w-full object-cover rounded-full" /> : <IoPerson className="h-full w-full p-1" />}
                        </div>
                        <h1 className="truncate">{`${userExp.user.firstName} ${userExp.user.lastName}`}</h1>
                        {user.id == userExp.user._id && <FaChild className="text-blue-400 h-[30px] w-[30px]" />}
                    </div>

                    <span className="uppercase min-w-fit">{`${formatNumber(userExp.xp)} ${translate("xp")}`}</span>

                    <div className="flex gap-x-2 items-center w-full justify-end">
                        {
                            rankReward?.name &&
                            <h1 className="capitalize truncate">
                                {
                                    rankReward.description ?
                                        <Link className="hover-link" href={`/${lang}/dashboard/ranking-reward/${rankReward._id}`}>{rankReward.name}</Link>
                                        : <span className="truncate">{rankReward.name}</span>
                                }
                            </h1>
                        }

                        {rankReward?.image ? <img src={rankReward.image} className="h-[50px] min-w-[50px] border-2 border-slate-400 object-cover rounded-full" /> : <FaMedal className="h-[30px] w-[30px] text-slate-400" />}
                    </div>
                </div>
            )
        },
        ({ userExp }) => { // bronze (index 2)
            const rankReward = ranking.currentYearRankRewards.find(rr => rr.rank == userExp.rank)

            return (
                <div className="flex gap-x-2 p-2 items-center justify-between bg-[#CD7F32]/10 rounded">
                    <div className="flex gap-x-2 items-center w-full truncate">
                        {userExp.lastStatusUp ? <FaChevronUp className="text-green-600 min-w-[18px] h-fit" /> : <FaChevronDown className="text-red-600 min-w-[18px] h-fit" />}
                        <span>{userExp.rank}</span>
                        <div className="rounded-full h-[50px] min-w-[50px] border-2 border-[#CD7F32]">
                            {userExp.user.image ? <img src={userExp.user.image} className="h-full w-full object-cover rounded-full" /> : <IoPerson className="h-full w-full p-1" />}
                        </div>
                        <h1 className="truncate">{`${userExp.user.firstName} ${userExp.user.lastName}`}</h1>
                        {user.id == userExp.user._id && <FaChild className="text-blue-400 h-[30px] w-[30px]" />}
                    </div>

                    <span className="uppercase min-w-fit">{`${formatNumber(userExp.xp)} ${translate("xp")}`}</span>

                    <div className="flex gap-x-2 items-center w-full justify-end">
                        {
                            rankReward?.name &&
                            <h1 className="capitalize truncate">
                                {
                                    rankReward.description ?
                                        <Link className="hover-link" href={`/${lang}/dashboard/ranking-reward/${rankReward._id}`}>{rankReward.name}</Link>
                                        : <span className="truncate">{rankReward.name}</span>
                                }
                            </h1>
                        }

                        {rankReward?.image ? <img src={rankReward.image} className="h-[50px] min-w-[50px] border-2 border-[#CD7F32] object-cover rounded-full" /> : <FaMedal className="h-[30px] w-[30px] text-[#CD7F32]" />}
                    </div>
                </div>
            )
        },
        ({ userExp }) => {  // default (index >= 3)
            const rankReward = ranking.currentYearRankRewards.find(rr => rr.rank == userExp.rank)

            return (
                <div className="flex gap-x-2 p-2 items-center justify-between rounded bg-blue-50">
                    <div className="flex gap-x-2 items-center w-full truncate">
                        {userExp.lastStatusUp ? <FaChevronUp className="text-green-600 min-w-[18px] h-fit" /> : <FaChevronDown className="text-red-600 min-w-[18px] h-fit" />}
                        <span>{userExp.rank > 0 ? userExp.rank : "?"}</span>
                        <div className="rounded-full h-[50px] min-w-[50px] border-2 border-blue-400">
                            {userExp.user.image ? <img src={userExp.user.image} className="h-full w-full object-cover rounded-full" /> : <IoPerson className="h-full w-full p-1" />}
                        </div>
                        <h1 className="truncate">{`${userExp.user.firstName} ${userExp.user.lastName}`}</h1>
                        {user.id == userExp.user._id && <FaChild className="text-blue-400 h-[30px] w-[30px]" />}
                    </div>

                    <span className="uppercase min-w-fit">{`${formatNumber(userExp.xp)} ${translate("xp")}`}</span>

                    <div className="flex gap-x-2 items-center w-full justify-end capitalize">
                        {
                            userExp.rank > 0 ?
                                <Fragment>
                                    {
                                        rankReward?.name &&
                                        <h1 className="capitalize truncate">
                                            {
                                                rankReward.description ?
                                                    <Link className="hover-link" href={`/${lang}/dashboard/ranking-reward/${rankReward._id}`}>{rankReward.name}</Link>
                                                    : <span className="truncate">{rankReward.name}</span>
                                            }
                                        </h1>
                                    }
                                    {rankReward?.image ? <img src={rankReward.image} className="h-[50px] min-w-[50px] border-2 border-blue-400 object-cover rounded-full" /> : <GiSportMedal className="text-blue-400" fontSize={30} />}
                                </Fragment>
                                : `${translate("you are out of ranking in")} ${currentYear}!`
                        }
                    </div>
                </div>
            )
        }
    ];

    const LateRankExpCard = rankCards[3]

    return (
        <div className="flex flex-col gap-y-2 h-[80vh] w-[768px] overflow-y-auto">
            <h1 className="text-3xl my-2 text-blue-400 font-semibold">
                <span className="uppercase">{translate(`xp`)}</span>
                <span className="capitalize"> {translate(`poitns ranking`)} </span>
                <span>{currentYear}</span>
            </h1>
            {
                !ranking ? <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-[15%] h-fit mx-auto" />
                    :
                    <div className="flex flex-col flex-grow gap-y-2 justify-between">
                        <div className="flex flex-col gap-y-2">
                            {
                                ranking.sortedYearExps.map((userExp, idx) => {
                                    const RankCard = rankCards[idx] || rankCards[3]; // Use gold, silver, bronze, or default

                                    return <RankCard key={idx} userExp={userExp} />;
                                })
                            }
                        </div>

                        <div className="flex flex-col flex-grow gap-y-2 justify-end">
                            {
                                lateRankExp &&
                                <Fragment>
                                    <div className="flex flex-col flex-grow justify-center gap-y-2 text-xl font-bold">
                                        <span>.</span>
                                        <span>.</span>
                                        <span>.</span>
                                    </div>

                                    <LateRankExpCard userExp={lateRankExp} />
                                </Fragment>
                            }

                            <h1 className="font-semibold text-gray-500 italic">
                                <span className="capitalize">{translate("Rewards")} </span>
                                <span>{translate("will be granted by end of december")} {currentYear}</span>
                            </h1>
                        </div>
                    </div>
            }

        </div>
    )
}

export default withStore(YearRanking)