"use client"

import { useEffect, Fragment, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { dev_console_log } from "@utils"
import { socketEmit } from "@lib/reducers/socketReducer"
import { usePathname } from "next/navigation"
import { useTranslate } from '@hooks';
import Script from 'next/script';


const ActivityTracker = ({ reference }) => {
    const pathname = usePathname()
    const dispatch = useDispatch()
    const translate = useTranslate()

    useEffect(() => {
        let gainedSecs = 0;
        let intervalId;
        let ytPlayers = [];
        let timeoutId;
        let active = true;

        const inactivityTimeout = 5 * 60 * 1000;
        const events = ['keydown', 'scroll', 'click', 'mousemove'];

        const startGainingMinutes = () => {
            // const lastGainedSecs = localStorage.getItem(`${pathname}-lastGainedSecs`)
            // gainedSecs = lastGainedSecs ? parseInt(lastGainedSecs, 10) : 0;

            dev_console_log("start gain", gainedSecs)

            // Increment the gainedSecs every minute
            intervalId = setInterval(() => {
                gainedSecs += 1
                // localStorage.setItem(`${pathname}-lastGainedSecs`, gainedSecs);

                dev_console_log("update gain", gainedSecs)

                if (+gainedSecs == 5 * 60) {
                    dispatch(socketEmit({
                        event: 'update-activity',
                        data: { route: pathname, gainedMinutes: +(gainedSecs / 60).toFixed(2), reference },
                        callback: ({ success }) => {
                            if (!success) return;
    
                            // if db updated successfully
                            gainedSecs = 0
                            // localStorage.setItem(`${pathname}-lastGainedSecs`, gainedSecs)
                        }
                    }));
                }
            }, 1000);
        }

        const resetActivityTimer = () => {
            clearTimeout(timeoutId);

            if (!active) {
                active = true;
                Swal.close();

                dispatch(socketEmit({
                    event: 'active',
                    data: { route: pathname },
                }));

                startGainingMinutes()
            }

            timeoutId = setTimeout(() => {
                if (ytPlayers.some(ytP => ytP.getPlayerState() == YT.PlayerState.PLAYING)) {
                    dev_console_log(`some video is playing`);

                    return resetActivityTimer()
                }

                active = false;

                Swal.fire({
                    icon: "question",
                    showConfirmButton: false,
                    title: translate("where did you go ?"),
                });

                dispatch(socketEmit({
                    event: 'inactive',
                    data: { route: pathname, gainedMinutes: +(gainedSecs / 60).toFixed(2), reference },
                    callback: ({ success }) => {
                        if (!success) return;

                        // if db updated successfully
                        gainedSecs = 0
                        // localStorage.setItem(`${pathname}-lastGainedSecs`, gainedSecs)
                    }
                }));

                // stop inc gain mins
                clearInterval(intervalId);
            }, inactivityTimeout);
        };


        const handleVisibilitychange = () => {
            if (document.hidden) {
                clearTimeout(timeoutId);
                active = false;

                Swal.fire({
                    icon: "question",
                    showConfirmButton: false,
                    title: translate("where did you go ?"),
                });

                dispatch(socketEmit({
                    event: 'left-window',
                    data: { route: pathname, gainedMinutes: +(gainedSecs / 60).toFixed(2), reference },
                    callback: ({ success }) => {
                        if (!success) return;

                        // if db updated successfully
                        gainedSecs = 0
                        // localStorage.setItem(`${pathname}-lastGainedSecs`, gainedSecs)
                    }
                }));

                // stop inc gain mins
                clearInterval(intervalId);
            }
        };


        // init youtube videos players when youtube api ready
        window.onYouTubeIframeAPIReady = () => {
            const ytIframes = document.querySelectorAll('iframe[name="ka-player-iframe"]');
            if (ytIframes.length > 0) {
                ytIframes.forEach(iframe => {
                    // adjust width of iframe
                    iframe.style.marginLeft = 'auto';
                    iframe.style.marginRight = 'auto';
                    iframe.style.maxWidth = `${iframe.offsetWidth}px`;
                    iframe.width = "100%"

                    ytPlayers.push(new YT.Player(iframe.id))
                })

                dev_console_log(`found ${ytIframes.length} yt-iframes, ${ytPlayers.length} yt-players are loaded`);
            } else {
                dev_console_log(`couldn't find any yt-iframes`);
            }
        }

        // start calculating active minutes
        startGainingMinutes()

        // Set up event listeners for user activity
        events.forEach(event => window.addEventListener(event, resetActivityTimer));
        window.addEventListener('visibilitychange', handleVisibilitychange);

        // Initialize activity timer
        resetActivityTimer();


        // Cleanup function to remove listeners and timeouts
        return () => {
            // save gainedSecs on unmount
            dispatch(socketEmit({
                event: 'unmount',
                data: { route: pathname, gainedMinutes: +(gainedSecs / 60).toFixed(2) },
            }));

            // clear interval and localStorage
            clearInterval(intervalId);
            // localStorage.removeItem(`${pathname}-lastGainedSecs`)

            // clear event litener
            events.forEach(event => window.removeEventListener(event, resetActivityTimer));
            window.removeEventListener('visibilitychange', handleVisibilitychange);
        };
    }, []);


    return (
        <Fragment>
            <div hidden id="activity-tracker" />
            <Script
                src="https://www.youtube.com/iframe_api"
                strategy="afterInteractive"
            />
        </Fragment>
    )
};

export default ActivityTracker;
