"use client"

import { getLang } from "@lib/reducers/langReducer"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Loading } from "@client-components"
import { withStore } from "@store-components"


const Root = () => {
    const lang = useSelector(getLang)
    const router = useRouter()

    useEffect(() => {
        router.replace(`/${lang}`)
    }, [lang, router])

    return <Loading />
}


export default withStore(Root)