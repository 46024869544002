"use client"

import { Fragment, useState } from "react"
import { IoPerson } from "@react-icons/all-files/io5/IoPerson"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import { useTranslate } from "@hooks";
import Link from "next/link";
import { useParams } from "next/navigation";


const ChildCard = ({ child }) => {
    const translate = useTranslate()
    const [show, setShow] = useState({ personal: false })
    const { lang } = useParams()

    return (
        <div className="bg-gray-100 border border-gray-300 shadow p-4 flex gap-x-4 rounded-lg">
            <div className="rounded-full h-[100px] min-w-[100px] border-2 border-gray-500">
                {child.image ? <img src={child.image} className="h-full w-full object-cover rounded-full" /> : <IoPerson className="h-full w-full p-2" />}
            </div>

            <div className="grid grid-cols-3 w-full">
                {/* full name */}
                <span className="font-semibold truncate text-lg w-fit capitalize">{translate(`Full Name: `)}</span>
                <span className="text-lg font-semibold col-span-2">{`${child.firstName} ${child.lastName}`}</span>

                {/* plan details */}
                <span className="text-sm text-gray-500 capitalize italic">
                    {translate(`Current Plan: `)} {child.plan?.current?.name || translate(`Not Subscribed`)}
                </span>

                {
                    child.plan &&
                    <div className="text-sm text-gray-500 col-span-2 capitalize italic">
                        <span>{new Date(child.plan.startDate).toDateString()}</span> - <span>{new Date(child.plan.renewDate).toDateString()}</span>
                    </div>
                }

                <div className="h-2 col-span-3" />

                <button className="col-span-3 text-blue-500 flex items-center gap-x-2 hover:underline w-fit" onClick={() => setShow({ ...show, personal: !show.personal })}>
                    <span className="font-semibold truncate capitalize">{translate(`Personal Info `)}</span>
                    <MdExpandMore className={`transition-transform duration-500 ${show.personal ? '-rotate-180' : 'rotate-0'}`} />
                </button>

                {
                    show.personal &&
                    <Fragment>
                        {/* email */}
                        <span className="font-semibold capitalize truncate text-sm w-fit animate-fade">{translate(`Email: `)}</span>
                        <span className="w-full text-sm col-span-2 animate-fade">{child.email}</span>

                        {/* phone */}
                        <span className="font-semibold capitalize truncate text-sm w-fit animate-fade">{translate(`Phone: `)}</span>
                        <span className="w-full text-sm col-span-2 animate-fade">{child.phone}</span>

                        {/* schoolSystem */}
                        <span className="font-semibold truncate capitalize text-sm w-fit animate-fade">{translate(`School System: `)}</span>
                        <span className="w-full text-sm col-span-2 animate-fade">{child.schoolSystem.name}</span>

                        {/* gradeLevel */}
                        <span className="font-semibold truncate capitalize text-sm w-fit animate-fade">{translate(`Grade Level: `)}</span>
                        <span className="w-full text-sm col-span-2 animate-fade">{child.gradeLevel.name}</span>
                    </Fragment>
                }

                <div className="h-2 col-span-3" />

                {/* study log */}
                <div className="flex flex-wrap items-center gap-2 col-span-3 text-sm">
                    <Link href={`/${lang}/dashboard/studylog/${child._id}`} className="submit-btn-outline">{translate(`Preview Study Log`)}</Link>
                    <Link href={`/${lang}/dashboard/student-progress/${child._id}`} className="submit-btn-outline">{translate(`Preview Progress`)}</Link>
                    <Link href={`/${lang}/dashboard/student-performance/${child._id}`} className="submit-btn-outline">{translate(`Preview Performance`)}</Link>
                </div>
            </div>
        </div>
    )
}

export default ChildCard