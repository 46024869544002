"use client"

import { BACKEND_ROOT } from "@constants";
import { AxiosRequest, dev_console_log } from "@utils";
import { useEffect } from "react"
import { useState } from "react"
import Select from "react-select";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import { useTranslate } from "@hooks";
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline";


const AssignCoursesToTeachers = ({ onSuccess }) => {
    const translate = useTranslate()
    const [options, setOptions] = useState(null)
    const [selected, setSelected] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const required = {
        "courses": translate("Courses List"),
        "teachers": translate("Teachers List"),
    }

    useEffect(() => {
        AxiosRequest.get(`${BACKEND_ROOT}/course/getCoursesTeachersOptions`).then(res => {
            const { courses, teachers } = res.data

            setOptions({
                courses: courses.map(c => ({
                    value: c._id, label: c.title
                })),
                teachers: teachers.map(t => ({
                    value: t._id,
                    label: `${t.firstName && t.lastName ? `${t.firstName} ${t.lastName}` : t.firstName || t.lastName || t.email} (${t.role})`
                }))
            })
        }).catch(err => {
            dev_console_log(err.message || err.code)
        })
    }, [])

    const handleConfirmAssign = async (e) => {
        e.preventDefault()

        // reset errors
        setErrors({})

        // check for missing required fields
        let missing = {};
        for (let key of Object.keys(required)) {
            if (!selected[key] || selected[key].length == 0) {
                missing[key] = `${required[key]} field is required!`;
            }
        }
        if (Object.keys(missing).length !== 0) return setErrors(missing);

        setLoading(true)
        await AxiosRequest.post(`${BACKEND_ROOT}/course/assignCoursesToTeachers`, {
            courses: selected.courses.map(c => c.value),
            teachers: selected.teachers.map(t => t.value),
        }).then(res => {
            onSuccess(res.data)
        }).catch(err => {
            console.info(err.message)
        })
        setLoading(false)
    }

    if (!options) return <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-1/6 max-w-[100px] mx-auto h-fit" />
    return (
        <form onSubmit={handleConfirmAssign} className="flex flex-col gap-y-4">
            <h1 className="text-2xl font-bold text-blue-500">{translate("Assign Courses To Teachers")}</h1>

            <div className="flex flex-col gap-y-2 text-start">
                <h2 className="font-semibold text-lg">{required.courses} <span className="text-red-600">*</span></h2>
                <Select
                    options={options.courses}
                    isMulti
                    isSearchable
                    value={selected.courses}
                    onChange={courses => {
                        setErrors(prev => {
                            delete prev.courses

                            return prev
                        })
                        setSelected(prev => ({ ...prev, courses }))
                    }}
                    placeholder={translate("Choose courses...")}
                    menuPortalTarget={document.body}
                    menuPosition='fixed'
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 100 }),
                    }}
                />

                {
                    errors.courses &&
                    <small className='text-red-700 flex items-center gap-1'>
                        <MdErrorOutline /> {translate(errors.courses)}
                    </small>
                }
            </div>

            <div className="flex flex-col gap-y-2 text-start">
                <h2 className="font-semibold text-lg">{required.teachers} <span className="text-red-600">*</span></h2>
                <Select
                    options={options.teachers}
                    isMulti
                    isSearchable
                    value={selected.teachers}
                    onChange={teachers => {
                        setErrors(prev => {
                            delete prev.teachers

                            return prev
                        })
                        setSelected(prev => ({ ...prev, teachers }))
                    }}
                    placeholder={translate("Choose teachers...")}
                    menuPortalTarget={document.body}
                    menuPosition='fixed'
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 100 }),
                    }}
                />

                {
                    errors.teachers &&
                    <small className='text-red-700 flex items-center gap-1'>
                        <MdErrorOutline /> {translate(errors.teachers)}
                    </small>
                }
            </div>

            <button className="submit-btn" disabled={loading || Object.values(errors).some(Boolean)}>
                {loading ? <AiOutlineLoading3Quarters className='animate-spin' /> : translate("Confirm Assign")}
            </button>
        </form>
    )
}

export default AssignCoursesToTeachers