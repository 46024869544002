"use client"

import { CustomModal, LearningTimesForm } from "@client-components"
import { BACKEND_ROOT } from "@constants"
import { useTranslate } from "@hooks"
import { getUser } from "@lib/reducers/userReducer"
import { AxiosRequest, dev_console_log } from "@utils"
import Link from "next/link"
import { useParams, usePathname, useRouter } from "next/navigation"
import { Fragment, useState } from "react"
import { useSelector } from "react-redux"


const CourseCard = ({ course }) => {
    const user = useSelector(getUser)
    const translate = useTranslate()

    const router = useRouter()
    const pathname = usePathname()
    const { lang } = useParams()

    const [modal, setModal] = useState({ open: false })
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    const handleEnroll = async (body) => {
        await AxiosRequest.post(`${BACKEND_ROOT}/course/createCourseProgress/${course._id}`, body)
            .then(res => {
                closeModal()
                router.push(`${pathname}/course-progress/${res.data.course}`)
            }).catch(err => {
                dev_console_log(err.message || err.code);
            })
    }

    return (
        <div className="flex flex-col rounded-lg shadow bg-gray-100 border border-gray-300 overflow-hidden w-[400px] mx-auto">
            <div className="relative hover:scale-110 transition-all duration-500">
                <img src={course.image || "/logo-2.png"} className="h-[150px] w-full object-cover" />
                <Link href={`/${lang}/course/${course.seoTxt}`} className="absolute inset-0 hover:bg-opacity-40 bg-black bg-opacity-0 transition-all duration-500" />
            </div>

            <div className="flex-grow flex flex-col p-4 gap-y-4 justify-between">
                <h1 className="font-bold text-2xl text-blue-400">{course.title}</h1>

                <div className="grid grid-cols-2 gap-x-2 font-semibold text-gray-400">
                    {
                        course.type == 'academic' &&
                        <Fragment>
                            <h2>{translate(`Grade Level:`)}</h2>
                            <h2>{translate(course.gradeLevel.name)}</h2>

                            <h2>{translate(`Educational System:`)}</h2>
                            <h2>{translate(course.educationalSystem.name)}</h2>
                        </Fragment>
                    }

                    {
                        course.type == 'professional' &&
                        <Fragment>
                            <h2>{translate(`Pathway:`)}</h2>
                            <h2>{translate(course.pathway.name)}</h2>

                            <h2>{translate(`Order in Pathway:`)}</h2>
                            <h2>{course.order}</h2>
                        </Fragment>
                    }
                </div>

                {/* enroll */}
                <div className="flex flex-col gap-y-2">
                    <button className='submit-btn' onClick={() => {
                        if (!user) return router.push(`/${lang}/login`);

                        openModal()
                    }}>{translate(user.permissionLevel > 3 ? `Enroll (As Student)` : `Enroll Now`)}</button>

                    {user.permissionLevel > 3 && <Link href={`/${lang}/dashboard/course-progress/${course._id}`} className='submit-btn-outline'>{translate(`Enroll (As Teacher)`)}</Link>}
                </div>

            </div>

            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-[1080px] min-w-[720px]`}>
                <LearningTimesForm handleFormAction={handleEnroll} />
            </CustomModal>
        </div>
    )
}


export default CourseCard