"use client"

import { FlyInOutTransition, ProgressHeatmap } from '@client-components'
import { useEffect, useState } from 'react'
import { AiOutlineSafetyCertificate } from "@react-icons/all-files/ai/AiOutlineSafetyCertificate"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import Link from 'next/link'
import { useTranslate } from '@hooks'
import colors from "tailwindcss/colors"
import { Line } from 'rc-progress';


const ProgressCourseCard = ({ progress }) => {
    const translate = useTranslate()

    const [expand, setExpand] = useState(false)
    const [completion, setCompletion] = useState(0)

    const calculateMaxPerformanceLevel = allQuestions => {
        const binaryMap = [0, 0, 0]; // Initial binary mapping for difficulty levels [3, 2, 1]

        // proccess allQuestions based on max levelType to get level
        if (allQuestions.length > 0) {
            // filter range questions to get passed questions
            allQuestions.filter(q => q.passed).forEach(q => {
                binaryMap[3 - q.difficultyLevel] = 1;
            });

            // all questions is not passed 
            if (binaryMap.every(digit => digit == 0)) {
                // needs improvements
                binaryMap[3 - 1] = 1;
            }
        }

        // Convert binary mapping to decimal level
        const level = parseInt(binaryMap.join(''), 2);
        return level
    }


    const mapLevelCompletionRatio = (level) => {
        const levelsCompletionRatios = [0, 0.20, 0.50, 0.70, 0.80, 0.90, 0.95, 1]

        return levelsCompletionRatios[level]
    }

    useEffect(() => {
        // do some calculations on learningPerformance
        const learningPerformance = progress.learningPerformance

        const performanceLevels = learningPerformance.map(lp => ({ lo: lp.learningOutcome, level: calculateMaxPerformanceLevel(lp.questions) }))

        const total = performanceLevels.length
        const completionRatios = performanceLevels.map(i => mapLevelCompletionRatio(i.level))
        const totalCompletionRatio = ((completionRatios.reduce((a, b) => a + b, 0) / total) * 100).toFixed(2)

        setCompletion(+totalCompletionRatio > 1 ? totalCompletionRatio : 1)
    }, [progress])

    return (
        <div className={`w-full flex flex-col p-4 rounded-lg shadow bg-gray-100 border border-gray-300 transition-all`}>
            <div className="flex justify-between gap-x-2">
                {/* details */}
                <div className="flex flex-col justify-between gap-y-4 w-full">
                    {/* title */}
                    <button className="w-fit flex items-center gap-x-2 hover:underline font-bold text-2xl text-blue-400" onClick={() => setExpand(!expand)}>
                        <h1>{progress.course.title}</h1>
                        <MdExpandMore className={`transition-transform duration-500 ${expand ? 'rotate-180' : 'rotate-0'}`} />
                    </button>

                    {/* learning outcomes */}
                    <FlyInOutTransition show={expand} as="h2" className={`${expand ? "flex gap-x-2 font-semibold text-gray-400" : "hidden"}`}>
                        <span>{translate(`Number of Learning Outcomes:`)}</span>
                        <span>{progress.course.learningOutcomes.length}</span>
                    </FlyInOutTransition>

                    {/* completion */}
                    <FlyInOutTransition show={!expand} as="div" className={`${expand ? 'hidden' : 'flex flex-col'}`}>
                        <h1 className='text-blue-400 font-bold'>{translate(`Completion Percentage:`)}</h1>
                        <Line
                            strokeWidth={2}
                            trailWidth={2}
                            percent={completion}
                            trailColor={colors.blue[100]}
                            strokeColor={colors.blue[400]}
                        />
                    </FlyInOutTransition>

                    {/* buttons */}
                    <div className='flex gap-x-2 text-sm'>
                        <Link href={`dashboard/course-progress/${progress.course._id}`} className='submit-btn'>{translate(progress.userCourseRole == "Teacher" ? `View Course` : `Continue Learning`)}</Link>
                        <button className='submit-btn-outline'>{translate(`Benchmark Assessment`)}</button>
                    </div>
                </div>

                {/* logo */}
                <AiOutlineSafetyCertificate className="h-[150px] w-fit text-blue-400 max-md:hidden" />
            </div>

            {/* dynamic gap */}
            <div className={`w-full transition-all duration-500 ${expand ? 'h-4' : 'h-0'}`} />

            {/* heatmap */}
            <ProgressHeatmap p={progress} expand={expand} />

            {/* dynamic gap */}
            <div className={`w-full transition-all duration-500 ${expand ? 'h-4' : 'h-0'}`} />

            {/* completion */}
            <div className={`flex flex-col transition-all duration-500 ${expand ? 'h-[44px] overflow-auto' : 'h-0 overflow-hidden'}`}>
                <h1 className='text-blue-400 font-bold'>{translate(`Completion Percentage:`)}</h1>
                <Line
                    strokeWidth={2}
                    trailWidth={2}
                    percent={completion}
                    trailColor={colors.blue[100]}
                    strokeColor={colors.blue[400]}
                />
            </div>
        </div>
    )
}

export default ProgressCourseCard